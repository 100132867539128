$bgColor: #ededec;

.ve-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(186, 185, 185, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .ve-bg {
        display: flex;
        flex-direction: row;
        gap: 15px;
        background: $bgColor;
        border-radius: 8px;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);

        .ve-container {
            display: flex;
            flex-direction: column;
            background-color: #ffff;
            border-radius: 8px;
            width: 50rem;
            height: 30rem;
            padding: 10px 20px;
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
            overflow: hidden;

            .ve-border {
                border-bottom: 1px solid rgb(0, 0, 0);
                margin: 15px;
            }



            .ve-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;

                .ve-color::before {
                    content: 'ss';
                    background-color: red;
                    border: 5px solid red;
                    color: red;
                    border-radius: 50px;

                }
            }

            .ve-time-date {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 20px;

                img {
                    padding-top: 10px;
                    width: 30px;
                }
            }


            .ve-location {
                display: flex;
                flex-direction: row;
                gap: 20px;

                img {
                    width: 30px;
                }
            }

            .ve-details {
                display: flex;
                flex-direction: column;
                

                .ve-detail-title {
                    color: #0078D4;
                    margin-right: auto;
                    border-bottom: 3px solid #0078D4;
                }

                .ve-detail {
                    margin: 15px;
                }
            }
        }

        .ve-invitees{
            background-color: #ffff;
            width: 15rem;
            height: auto;
            border-radius: 8px;
            padding: 10px 20px;

            .ve-CloseBtn {
                position:relative;
                background: none;
                border: none;
                font-size: 1.5rem;
                font-weight: bolder;
                cursor: pointer;
                transform: translate(13rem);
                padding: 10px;
            }

            .ve-invitees-title{
                color: rgb(0, 0, 0);
            }

            .ve-people{
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
            }
        }
    }
}