.Students {
  width: 100%;
  position: relative;
}
.Students .studentListData-container {
  padding: 15px 0px 0px 0px;
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
  position: relative;
}
.Students .studentListData-container .container-leftSide {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}/*# sourceMappingURL=AdminStudentSec.css.map */