.BillingHistory {
    $elmComonDimmension: 200px;
    width: fit-content;

    .HeaderElm {
        display: flex;
        flex-direction: row;
        padding: 0px 0px 10px 0px;

        .elm {
            color: #868686;
            padding: 10px 0px 0px 20px;
            width: $elmComonDimmension;
        }

        .Receipt {
            color: #868686;
            padding: 10px 0px 0px 20px;
            width: fit-content;
        }
    }

    .historyList {
        height: 250px;
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;
        flex-direction: column;
        padding: 10px 0px 0px 10px;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888888;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #555555;
        }

        .receipt {
            display: flex;
            cursor: pointer;
            padding: 10px 10px 10px 10px;
            border-radius: 10px;
            width: fit-content;

            .elm {
                font-size: 14px;
                font-weight: 600;
                padding: 10px;
                width: $elmComonDimmension;
            }

            .downloadElm {
                border-radius: 10px;
                height: fit-content;
                background-color: white;
                padding: 8px 16px 8px 16px;
                outline: none;
                border: 1px solid #b8b8b8;
                cursor: pointer;
            }

            transition: all 0.4s;

            &:hover {
                box-shadow: 0px 0px 10px rgb(134, 134, 134, 0.5);
            }
        }
    }
}