.users-list {
    margin: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 50vh;
    overflow: scroll;

    .users-list-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 15px;

        h4 {
            display: flex;
            gap: 8px;

            span {
                color: #868686;
                font-weight: 400;
            }
        }

        .users-search-bar-wrapper {
            .users-search-bar {
                background-image: url('../../../../icons/searchIcon.svg');
                background-position: 5px;
                background-size: 2vh;
                padding: 10px 10px 10px 35px;
                background-repeat: no-repeat;
                outline: none;
                border: 1px solid #6d6d6d;
                border-radius: 10px;
                flex-grow: 1;
            }
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            padding: 12px;
            text-align: left;
            border-bottom: 1px solid #ddd;

            p {
                margin: 0;
            }
        }

        thead {
            background-color: #f4f4f4;

            th {
                font-weight: bold;
            }
        }

        tbody {
            // max-height: 100vh;
            overflow: scroll;

            tr {
                &:hover {
                    background-color: #f9f9f9;
                }
            }

            .user-profile {
                display: flex;
                align-items: center;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                p {
                    margin: 0;
                }
            }

            td {
                position: relative;

                .user-options {
                    width: 20px;
                    cursor: pointer;
                }

                .clicked-user-options {
                    background-color: #F2F2F2;
                    border-radius: 50%;
                    width: 20px;
                    padding: 15%;
                    cursor: pointer;
                }

                .dropdown-menu {
                    position: absolute;
                    background-color: white;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    padding: 10px;
                    width: 150px;
                    z-index: 100;
                    left: -150%;
                    top: 80%;

                    .menu-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 8px;
                        padding: 8px 12px;
                        cursor: pointer;
                        transition: background-color 0.3s ease;

                        &:hover {
                            background-color: #f5f5f5;
                        }


                        img {
                            width: 20px;
                        }



                        p {
                            margin: 0;
                            font-size: 14px;
                            color: #333;
                        }
                    }

                    .change-roles-menu {
                        p {
                            color: #5375E2;
                        }
                    }

                    .delete-user-menu {
                        p {
                            color: #ff0000;
                        }
                    }
                }
            }
        }
    }

}