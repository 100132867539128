.general-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Inter";

    .title {
        border-bottom: 1px solid #b8b8b8;
        padding: 0px 0px 10px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        h2 {
            margin: 0;
        }

        span {
            color: #868686;
        }
    }

    form {

        .input-wrap{
            position:relative;
            height: 37px;
            margin-bottom: 2rem;

            .input-field{
                position:absolute;
                width: 50%;
                height: 100%;
                background-color: none;
                border: none;
                outline: none;
                border-bottom: 1px solid #bbb;
                padding: 0;
                font-size: .95rem;
                color: #151111;
            }
            
            .input-label{
                position:absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: .95rem;
                color: #bbb;
                pointer-events: none;
                transform: translateY(-50%);
                transition: all 0.3s ease;
            }
            .label-animate {
                top: -5px;
                font-size: 0.8rem;
                color: #2C7F75; 
            }
        }

        .school-details {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .logo-update {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;

                img {
                    object-fit: cover;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background-color: #f2f2f2;
                }

                button {
                    height: 35px;
                    width: 100px;
                    border-radius: 8px;
                    cursor: pointer;
                }

                .upload-btn {
                    background-color: #2C7F75;
                    color: #ffffff;
                    border: none;
                }

                .delete-btn {
                    background-color: transparent;
                    color: #2C7F75;
                    border: 1px solid #2C7F75;
                }
            }

            .contact-form-wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                padding-bottom: 15px;
            }
            
        }
        .address-title{
            border-top: 1px solid #b8b8b8;

        }

        .address-form-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }

        .form-actions {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 20px;

            .submit-btn, .cancel-btn {
                height: 35px;
                width: 100px;
                border-radius: 8px;
                cursor: pointer;
                border: none;
                color: #fff;
            }

            .submit-btn {
                background-color: #2c7f75;
            }
            
            .cancel-btn {
                background-color: #ccc;
            }
        }
    }
}