.teachers-list-container {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    gap: 15px;
    flex-wrap: wrap;

    .teachers-list-grid {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: 15px;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px rgb(172, 172, 172);
        border-radius: 10px;
        padding: 0px 20px;
        width: 230px;
        height: 160px;

        .teachers-profile-pic {
            $radius: 80px;
            width: $radius;
            height: $radius;
            border-radius: 50%;
            box-shadow: 0px 0px 10px gray;

            img {
                width: $radius;
                border-radius: 50%;
            }
        }

        .teachers-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: fit-content;

            .teachers-fullname {
                h3 {
                    margin: 10px;
                    font-weight: 600;
                }
            }

            .teachers-disciplines {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-height: 60px;
                /* Set max height */
                overflow: hidden;

                .course {
                    color: #929292;
                    font-weight: 400;
                    max-width: 150px;
                    /* Set your desired width */
                    overflow: hidden;
                    /* Hide overflow text */
                    white-space: nowrap;
                    /* Prevent text wrapping */
                    text-overflow: ellipsis;
                }
            }

            .teachers-profile-link {
                cursor: pointer;

                p {
                    font-size: 12px;
                    font-weight: 500;
                    color: blue;
                }
            }
        }

    }
}