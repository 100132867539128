.FloatViewEvent {
    position: absolute;
    z-index: 1;
    width: 300px;
    height: fit-content;
    background-color: white;
    box-shadow: 0px 0px 10px gray;
    left: 130px;
    color: #666666;
    border-radius: 10px;

    .upperPart {
        padding: 8px 0px 8px 10px;
        background-color: #d6d6d6d6;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        display: flex;
        column-gap: 10px;

        .people {
            white-space: nowrap;
            display: flex;
            text-overflow: ellipsis;
            overflow-x: hidden;
            width: 172px;
        }

        img {
            cursor: pointer;
            width: 20px;
            justify-self: flex-end;
        }
    }

    .middlePart {
        row-gap: 10px;
        display: flex;
        flex-direction: column;

        h2 {
            padding: 8px 0px 8px 0px;
            margin: 0px 0px 0px 50px;
            width: 83%;
            border-bottom: 1px solid #dddddd;
            text-align: start;
        }

        .elem {

            display: flex;
            align-items: center;
            padding: 10px 0px 10px 20px;
            column-gap: 8px;

            img {
                width: 20px;
            }

            span {
                text-align: start;
                flex-grow: 1;
                padding: 0px 0px 5px 0px;
                border-bottom: 1px solid #dddddd;
            }
        }

        .elem1 {

            display: flex;
            align-items: center;
            padding: 10px 0px 10px 20px;
            column-gap: 8px;

            img {
                width: 20px;
            }

            span {
                text-align: start;
                flex-grow: 1;
                padding: 0px 0px 5px 0px;
            }
        }
    }
}