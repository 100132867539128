.RecurrentPayement {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .Title {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        h4 {
            margin: 0;
        }

        span {
            color: gray;
        }
    }

    .knob {
        $circleDim: 30px;
        width: $circleDim*2.5;
        height: $circleDim+7;
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.8);
        border-radius: $circleDim;
        position: relative;
        transition: all 0.7s;

        .circle {
            width: $circleDim;
            position: absolute;
            height: $circleDim;
            border-radius: 50%;
            top: 1.8px;
            left: 2px;
            box-shadow: 0px 0px 2px rgb(54, 54, 54);
            background-color: rgb(238, 235, 235);
            border: 2px solid white;
            transition: all 0.5s;
            z-index: 1;
            cursor: pointer;
        }

        .knobState {
            position: absolute;
            top: 9px;
            color: white;
            transition: all 0.7s;
        }

    }
}