.TeacherNavBar {
    display: flex;
    padding: 20px 10px;
    justify-content: space-between;
    column-gap: 10px;
    position: relative;

    .options {
        display: flex;
        column-gap: 10px;
        min-width: 350px;
        justify-content: space-between;

        %commonStruct {
            padding: 10px 30px;
            border: none;
            border-radius: 10px;
            font-size: 16px;
            box-shadow: 0px 0px 10px rgb(172, 172, 172);
            cursor: pointer;
        }

        .addTeacher {
            @extend %commonStruct;
            background-color: #0254ec;
            color: white;
            font-size: 16px;
        }

        .exportCsv {
            @extend %commonStruct;
            background-color: white;
        }
    }


    .searchEngTeacher {
        box-shadow: 0px 0px 10px rgb(172, 172, 172);
        border-radius: 10px;
        display: flex;
        padding: 0px 0px 0px 10px;
        column-gap: 6px;
        flex-grow: 2;

        img {
            width: 20px;
        }

        input {
            border-radius: 10px;
            padding: 10px 0px;
            border: none;
            font-size: 16px;
            outline: none;
            color: gray;
            width: 100%;
        }
    }
}