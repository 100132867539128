.connect-container {
    display: flex;
    flex-direction: column;
    background-color: #f5f7fa; // Adjust background color to match design
    padding: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); // Softer shadow
    width: 400px; // Adjust based on design width
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;

    .invite-description {
        width: 100%;

        .connect-action {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .connect-action-header {
                text-align: start;

                h4 {
                    margin: 10px 0 0 0;
                    font-size: 24px;
                    color: #333333; // Change color to match design
                }

                p {
                    font-size: 16px;
                    color: #777777; // Subtle text color
                }
            }

            .connect-action-body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;

                .connect-text {
                    font-size: 14px;
                    text-align: start;
                    color: #555555;
                    margin-bottom: 15px;
                }

                .connect-input {
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #dddddd;
                    border-radius: 6px;
                    font-size: 14px;
                    transition: border-color 0.3s ease;

                    &:focus {
                        border-color: #2C7F75;
                        outline: none;
                    }
                }

                .connect-button {
                    background-color: #2C7F75;
                    color: #ffffff;
                    border: none;
                    padding: 12px 20px;
                    border-radius: 6px;
                    font-size: 16px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: #718986;
                    }
                }
            }
        }
    }
}
