.filter-container .filter-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 25%;
  border-radius: 10px;
  background-color: white;
  padding: 0px 8px 0px 8px;
  box-shadow: 1px 1px 100px rgb(101, 101, 101);
  z-index: 1;
  width: 52vh;
  height: 72vh;
  gap: 8px;
  overflow: scroll;
}
.filter-container .filter-options .close-filter-container {
  display: flex;
  flex-direction: row;
  margin: 14px 8px 0px 0px;
  justify-content: flex-end;
}
.filter-container .filter-options .close-filter-container img {
  width: 24px;
  margin-bottom: 0px;
  cursor: pointer;
}
.filter-container .filter-options .filter-titles {
  font-size: 12px;
}
.filter-container .filter-options .filter-by-container,
.filter-container .filter-options .filterItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  border-bottom: 1px solid #8A8DAC;
}
.filter-container .filter-options .filter-by-data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  padding-bottom: 18px;
}
.filter-container .filter-options .filter-by-data button {
  color: #3C4177;
  background-color: transparent;
  border: 1px solid #8A8DAC;
  border-radius: 5px;
  padding: 5px;
}
.filter-container .filter-options .done-container {
  display: flex;
  flex-direction: row;
  margin: 14px 8px 0px 0px;
  justify-content: flex-end;
}
.filter-container .filter-options .done-container .done-button {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  background-color: #3C4177;
  border: 1px solid;
  border-radius: 5px;
  padding: 8px;
}
.filter-container .filter-options .selected-item button {
  background-color: #006576;
  color: #FFFFFF;
}/*# sourceMappingURL=Filter.css.map */