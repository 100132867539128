.Dashboard {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    height: 94%;

    .dashboard-container {
        display: flex;
        column-gap: 10px;
        height: inherit;

        .leftSideDashboard {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            row-gap: 16px;
        }

        .rigthSideDashboard {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            height: inherit;
        }
    }
}