.account-manager-container {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    
    .account-manager-subtitles{
        padding-top: 15px;
        font-size: 14px;
        h4{
            font-weight: 600;
            color: #868686;
        }

        p{
            font-size: 12px;
        }
    }

    .accounts-container {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        padding-right: 10px;
        .accounts-title {
            flex-grow: 1;
            p {
                font-size: 14px;
            }
        }

        .accounts-email-container {
            border: 1px solid #F2F2F2;
            border-radius: 5px;
            flex-grow: 2;
            width: 60%;
            .account-email {
                padding: 0 25px;
                border: .5px solid #F2F2F2;

                .email {
                    font-size: 12px;
                }
            }

            .accounts-email-function {
                display: flex;
                flex-direction: row;
                padding: 0 25px;
                gap: 8px;
                background-color: #F2F2F2;
                cursor: pointer;

                img {
                    color: #054DCE;
                    width: 24px;
                }

                p {
                    font-weight: 600;
                    font-size: 12px;
                    color: #054DCE;
                }
            }

        }
    }

    .account-password{
        button{
            height: 35px;
            width: auto;
            padding: 0 15px;
            border-radius: 5px;
            cursor: pointer;
            background-color: #2C7F75;
            color: #ffffff;
            border: none;
            
        }
    }

    .account-remove{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        gap: 15px;

        button{
            height: 35px;
            width: auto;
            padding: 0 15px;
            border-radius: 5px;
            cursor: pointer;
            background-color: #AF0101;
            color: #ffffff;
            border: none;
        }

        .account-del-btn{
            
            button{
                background-color: #F2F2F2;
                color: #AF0101;
            }

        }
    }

}