.Students {
    width: 100%;
    position: relative;

    .studentListData-container {
        padding: 15px 0px 0px 0px;
        display: flex;
        column-gap: 20px;
        position: relative;

        .container-leftSide {
            position: relative;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }
    }
}