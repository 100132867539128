.InterfaceTheme {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 20px 20px;
    border-bottom: 1px solid rgb(128, 128, 128, 0.5);
    align-items: center;

    .title {
        h3 {
            font-size: medium;
            margin: 20px 0px 0px 0px;
        }

        span {
            color: gray;
        }
    }

    justify-content: flex-start;
    column-gap: 60px;

    .modes {
        display: flex;
        column-gap: 40px;
        padding: 20px 0px 0px 0px;

        %commonLayoutMode {
            width: 100px;
            height: 160px;
            border: 4px solid #a8a8a8;
            border-radius: 10px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            position: relative;

            .tick {
                position: absolute;
                width: 25px;
                top: 8px;
                right: 35%
            }
        }

        .ligthMode {
            @extend %commonLayoutMode;
        }

        .darkMode {
            @extend %commonLayoutMode;
        }
    }

}