.Cancelation {
    margin: 50px 50px 0px 0px;
    border-radius: 10px;
    color: white;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 30px 20px 30px 20px;
    row-gap: 10px;
    background-color: #00878b;

    h2 {
        margin: 0px 0px 0px 0px;
    }

    button {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 10px 20px 10px 20px;
        width: fit-content;
        font-size: 16px;
        border-radius: 10px;
        transition: all 0.6s;
        cursor: pointer;

        &:hover {
            border: 1px solid #00383a;
            background-color: #00383a;
        }
    }
}
