.change-password-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.change-password-overlay .change-password-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}
.change-password-overlay .change-password-box .change-password-header {
  text-align: start;
}
.change-password-overlay .change-password-box .change-password-header h4 {
  margin: 0;
}
.change-password-overlay .change-password-box .change-password-header p {
  font-size: 14px;
  color: #868686;
}
.change-password-overlay .change-password-box form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.change-password-overlay .change-password-box form .email-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border: 1px solid #EDEFF3;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 80%;
}
.change-password-overlay .change-password-box form .email-input-wrapper .email-input {
  border: none;
  flex: 1;
  outline: none;
  padding: 5px;
  font-size: 14px;
  background-color: transparent;
}
.change-password-overlay .change-password-box form .change-password-buttons {
  display: flex;
  justify-content: space-between;
}
.change-password-overlay .change-password-box form .change-password-buttons button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  background-color: #F2F2F2;
  color: #000000;
}
.change-password-overlay .change-password-box form .change-password-buttons button:hover {
  opacity: 0.8;
}
.change-password-overlay .change-password-box form .change-password-buttons .send-button {
  background-color: #2C7F75;
  color: #ffffff;
}
.change-password-overlay .change-password-box form .change-password-buttons .back-button {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0%;
  background-color: transparent;
  color: #2C7F75;
  text-decoration: underline;
}/*# sourceMappingURL=ChangePassword.css.map */