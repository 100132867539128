.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.confirmation-overlay .confirmation-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}
.confirmation-overlay .confirmation-box p {
  font-size: 16px;
  margin-bottom: 20px;
}
.confirmation-overlay .confirmation-box .confirmation-buttons {
  display: flex;
  justify-content: space-between;
}
.confirmation-overlay .confirmation-box .confirmation-buttons button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  border: none;
}
.confirmation-overlay .confirmation-box .confirmation-buttons button:hover {
  opacity: 0.8;
}
.confirmation-overlay .confirmation-box .confirmation-buttons .delete-button {
  background-color: #d9534f;
  color: white;
}/*# sourceMappingURL=DeleteConfirmation.css.map */