.AddTeacher {
  position: absolute;
  box-shadow: 0px 0px 10px gray;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  row-gap: 20px;
  padding: 30px;
  left: 25%;
  max-width: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: 600ms appear forwards ease-in;
}
.AddTeacher .profilePicture {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AddTeacher .profilePicture .circle {
  height: 100px;
  width: 100px;
  border: 5px solid white;
  box-shadow: 0px 0px 10px gray;
  border-radius: 50%;
  cursor: pointer;
  background-color: white;
  position: relative;
}
.AddTeacher .profilePicture .circle .editAdd {
  position: absolute;
  box-shadow: 0px 0px 10px gray;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  bottom: -10px;
  right: 0px;
  background-color: white;
}
.AddTeacher .profilePicture .circle .editAdd img {
  width: 30px;
}
.AddTeacher .profilePicture .circle img {
  width: 100px;
  border-radius: 50%;
}
.AddTeacher .profilePicture .imageInputField {
  width: 0px;
  height: 0px;
}
.AddTeacher .contacts input, .AddTeacher .fullName input {
  padding: 10px 8px 10px 8px;
  outline: none;
  border: none;
  border-bottom: 1px solid gray;
  background-color: transparent;
}
.AddTeacher .contacts input::-moz-placeholder, .AddTeacher .fullName input::-moz-placeholder {
  color: black;
}
.AddTeacher .contacts input::placeholder, .AddTeacher .fullName input::placeholder {
  color: black;
}
.AddTeacher .fullName {
  display: flex;
  -moz-column-gap: 38px;
       column-gap: 38px;
}
.AddTeacher .fullName input {
  flex-grow: 1;
}
.AddTeacher .contacts {
  display: flex;
  -moz-column-gap: 38px;
       column-gap: 38px;
  row-gap: 16px;
  flex-wrap: wrap;
}
.AddTeacher .contacts input {
  flex-grow: 1;
}
.AddTeacher .contacts .address {
  flex-grow: 2;
}
.AddTeacher .genderDob {
  display: flex;
  justify-content: space-between;
}
.AddTeacher .genderDob .gender {
  position: relative;
  cursor: pointer;
}
.AddTeacher .genderDob .gender .selectedGender {
  border: 1px solid rgb(41, 41, 41);
  width: 120px;
  padding: 6px 10px;
  border-radius: 10px;
}
.AddTeacher .genderDob .gender .genderList {
  display: none;
  flex-direction: column;
  position: absolute;
  box-shadow: 0px 0px 10px gray;
  border-radius: 10px;
}
.AddTeacher .genderDob .gender .genderList .genderElm {
  border-bottom: 1px solid gray;
  width: 120px;
  padding: 6px 10px;
  background-color: white;
}
.AddTeacher .genderDob .gender .genderList .genderElm:hover {
  background-color: gray;
  color: white;
}
.AddTeacher .genderDob .dob {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.AddTeacher .genderDob .dob input {
  height: 100%;
  border-radius: 10px;
  border: 1px solid gray;
  padding: 0px 8px 0px 8px;
  font-family: "Inter";
  outline: none;
}
.AddTeacher .submission {
  display: flex;
  justify-content: space-between;
}
.AddTeacher .submission .discTeacherBtn, .AddTeacher .submission .addTeacherBtn {
  padding: 10px 40px;
  color: white;
  cursor: pointer;
  font-size: medium;
}
.AddTeacher .submission .addTeacherBtn {
  background-color: #0254ec;
  border: none;
}
.AddTeacher .submission .discTeacherBtn {
  background-color: #cc2002;
  border: none;
}

@keyframes appear {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}/*# sourceMappingURL=AddTeacher.css.map */