@import url("https://fonts.googleapis.com/css?family=Inter");
.calendar-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 10px;
  border-radius: 2%;
  padding: 0px 10px 10px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  font-family: "Inter";
}
.calendar-container .calendar-title {
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.calendar-container .calendar-title .calendar-icon {
  border: 0px solid transparent;
  background-image: url("../../../icons/calendarIcon.svg");
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  width: 30px;
  padding: 18px;
}
.calendar-container .calendar-title .calendarIcon {
  width: 30px;
}
.calendar-container .calendar-title .calendar-full {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  position: absolute;
  right: 10%;
  bottom: 36%;
  z-index: 1;
}
.calendar-container .date-buttons {
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 0px;
  -moz-column-gap: 20px;
       column-gap: 20px;
  width: -moz-fit-content;
  width: fit-content;
}
.calendar-container .date-buttons .date-non-clicked .date-days-container, .calendar-container .date-buttons .date-clicked .date-days-container {
  display: flex;
  padding: 20px 16px 20px 16px;
  flex-direction: column;
}
.calendar-container .date-buttons .date-clicked {
  border: 1px solid;
  border-radius: 5px;
  align-items: center;
  text-align: center;
  background-color: #212765;
  color: #F2F2F2;
}
.calendar-container .date-buttons .date-non-clicked {
  border: 1px solid;
  border-radius: 5px;
  align-items: center;
  text-align: center;
  background-color: #F2F2F2;
  color: #212765;
}
.calendar-container .events-container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  height: 47vh;
  padding: 10px 10px 10px 0px;
}
.calendar-container .events-container::-webkit-scrollbar {
  width: 8px;
}
.calendar-container .events-container::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 8px 0px 0px 0px;
}
.calendar-container .events-container::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}
.calendar-container .events-container::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}
.calendar-container .events-container .event-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #F5F5F5;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  border-left: 5px solid #4EF9E5;
}
.calendar-container .events-container .event-container .event-title {
  font-size: 12px;
  font-weight: bold;
  text-align: start;
  align-items: start;
  cursor: pointer;
}
.calendar-container .events-container .event-container .event-time {
  font-size: 8px;
  padding-top: 12px;
  text-align: end;
  cursor: pointer;
}/*# sourceMappingURL=CalendarEvents.css.map */