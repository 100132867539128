@import url('https://fonts.googleapis.com/css?family=Inter');

.AdminAcademicList {
  font-family: 'Inter';
  $paddingThTd: 10px;
  flex-grow: 1;
  $commonGray: #f2f2f2;
  box-shadow: 1px 1px 10px rgb(67, 67, 67);
  border-radius: 10px;
  overflow-y: auto;
  height: 47vh;

  .title {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 20px;
    display: block;
    color: #762984;
    font-weight: bold;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  table {
    padding: 0px 0px 20px 0px;
    width: 100%;

    thead {
      tr {
        background-color: $commonGray;

        th {
          color: #232323;
          width: 120px;
          text-align: left;
          padding: $paddingThTd;
          border: 2px solid $commonGray;
          font-weight: 600;
        }

        .statsColumn {
          text-align: center;
        }

        .nameColumn {
          width: 200px;
          padding: 0px 0px 0px 20px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: $paddingThTd;
        }

        .stats {
          text-align: center;
        }

        .names {
          padding: 0px 0px 0px 20px;
        }
      }
    }
  }

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin-top: 60px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  }
}