.team-manager-container {
    .team-manager-permissions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 50px;

        .user-permissions-title {
            p {
                font-weight: 600;
                font-size: 14px;
            }
        }

        .user-permissions-btn {
            button {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;

                img {
                    width: 25px;
                }

                height: 35px;
                width: auto;
                padding: 0 15px;
                border-radius: 5px;
                cursor: pointer;
                background-color: #ffffff;
                color: #2C7F75;
                border: 1px solid #2C7F75;
            }
        }
    }
}