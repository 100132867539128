.FloatViewEvent {
  position: absolute;
  z-index: 1;
  width: 300px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  box-shadow: 0px 0px 10px gray;
  left: 130px;
  color: #666666;
  border-radius: 10px;
}
.FloatViewEvent .upperPart {
  padding: 8px 0px 8px 10px;
  background-color: rgba(214, 214, 214, 0.8392156863);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.FloatViewEvent .upperPart .people {
  white-space: nowrap;
  display: flex;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 172px;
}
.FloatViewEvent .upperPart img {
  cursor: pointer;
  width: 20px;
  justify-self: flex-end;
}
.FloatViewEvent .middlePart {
  row-gap: 10px;
  display: flex;
  flex-direction: column;
}
.FloatViewEvent .middlePart h2 {
  padding: 8px 0px 8px 0px;
  margin: 0px 0px 0px 50px;
  width: 83%;
  border-bottom: 1px solid #dddddd;
  text-align: start;
}
.FloatViewEvent .middlePart .elem {
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 20px;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.FloatViewEvent .middlePart .elem img {
  width: 20px;
}
.FloatViewEvent .middlePart .elem span {
  text-align: start;
  flex-grow: 1;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #dddddd;
}
.FloatViewEvent .middlePart .elem1 {
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 20px;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.FloatViewEvent .middlePart .elem1 img {
  width: 20px;
}
.FloatViewEvent .middlePart .elem1 span {
  text-align: start;
  flex-grow: 1;
  padding: 0px 0px 5px 0px;
}/*# sourceMappingURL=FloatViewEvent.css.map */