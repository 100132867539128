.PopUpEditAdd {
  position: absolute;
  box-shadow: 0px 0px 10px #353434;
  width: 560px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  left: 15%;
  top: 0%;
  z-index: 1;
}
.PopUpEditAdd .closeIcon {
  width: 30px;
  align-self: flex-end;
  cursor: pointer;
}
.PopUpEditAdd .fields {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
}
.PopUpEditAdd .fields label {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.PopUpEditAdd .fields label span {
  font-family: "Inter";
}
.PopUpEditAdd .fields label input[type=date], .PopUpEditAdd .fields label input[type=number], .PopUpEditAdd .fields label input[type=text] {
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
  height: 35px;
  outline: none;
  border: 1px solid gray;
}
.PopUpEditAdd .fields label input[type=number]::-webkit-inner-spin-button,
.PopUpEditAdd .fields label input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.PopUpEditAdd .fields label input[type=date] {
  width: 160px;
}
.PopUpEditAdd .fields label .knob {
  width: 75px;
  height: 37px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.8);
  border-radius: 30px;
  position: relative;
  transition: all 0.7s;
}
.PopUpEditAdd .fields label .knob .circle {
  width: 30px;
  position: absolute;
  height: 30px;
  border-radius: 50%;
  top: 1.8px;
  left: 2px;
  box-shadow: 0px 0px 2px rgb(54, 54, 54);
  background-color: rgb(238, 235, 235);
  border: 2px solid white;
  transition: all 0.5s;
  z-index: 1;
  cursor: pointer;
}
.PopUpEditAdd .fields .FileInput {
  display: flex;
  flex-direction: row;
  width: -moz-fit-content;
  width: fit-content;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.PopUpEditAdd .fields .FileInput img {
  max-width: 60px;
}
.PopUpEditAdd .fields .FileInput .delImg, .PopUpEditAdd .fields .FileInput .addImg {
  padding: 8px 20px 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}
.PopUpEditAdd .fields .FileInput .addImg {
  background-color: #2C7F75;
  color: white;
}
.PopUpEditAdd .fields .FileInput .delImg {
  color: rgb(77, 77, 77);
  background-color: transparent;
  border: 2px solid rgb(190, 190, 190);
}
.PopUpEditAdd .fields .SubField {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.PopUpEditAdd .fields .SubField .SubFields {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.PopUpEditAdd .saveDiscard {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding-top: 20px;
}
.PopUpEditAdd .saveDiscard .discard, .PopUpEditAdd .saveDiscard .save {
  padding: 10px 30px 10px 30px;
  border-radius: 10px;
  cursor: pointer;
}
.PopUpEditAdd .saveDiscard .save {
  background-color: #2C7F75;
  color: white;
  border: 1px solid transparent;
  transition: all 0.7s;
}
.PopUpEditAdd .saveDiscard .save:hover {
  background-color: #1a524c;
}
.PopUpEditAdd .saveDiscard .discard {
  background-color: transparent;
  border: 1px solid gray;
  transition: all 0.7s;
}
.PopUpEditAdd .saveDiscard .discard:hover {
  background-color: rgb(222, 222, 222);
  border: 1px solid rgb(222, 222, 222);
}/*# sourceMappingURL=PopUpEdit.css.map */