.CalendarMini {
  display: flex;
  flex-direction: column;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  padding: 10px;
}
.CalendarMini .CalendarMini-Header {
  display: flex;
  align-items: center;
  gap: 6rem;
  width: 80%;
}
.CalendarMini .CalendarMini-Header .CalendarMini-Month {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: start;
}
.CalendarMini .CalendarMini-Header .CalendarMini-AddIcon {
  flex-grow: 1;
  font-size: 1.7rem;
  text-align: end;
  cursor: pointer;
}
.CalendarMini .CalendarMini-Grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}
.CalendarMini .CalendarMini-Day {
  width: 40px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.CalendarMini .CalendarMini-Day.is-today {
  border: 1px solid #0078d5;
  border-radius: 50%;
}
.CalendarMini .CalendarMini-Day.is-selected {
  background-color: #0078d5;
  color: white;
  border-radius: 50%;
}/*# sourceMappingURL=CalendarMini.css.map */