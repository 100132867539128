.InfoCard {
    box-shadow: 0px 0px 10px rgb(172, 172, 172);
    border-radius: 10px;
    padding: 10px 25px 16px 25px;
    max-width: 320px;
    font-family: "Inter";
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 14px;

    .UpperPart {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        .legend {
            display: flex;
            justify-content: space-between;

            h3 {
                margin: 10px 0px 10px 0px;
            }

            img {
                width: 25px;
                cursor: pointer;
            }
        }

        .profile {
            align-self: center;
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 16px;
            color: #0254ec;

            .InfoCard-Name-disciplines {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .Name {
                    margin: 0px;

                }

                .disciplines {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .course {
                        font-size: 13px;
                    }
                }
            }


            .profileCirle {
                $radius: 100px;
                width: $radius;
                height: $radius;
                border-radius: 50%;
                box-shadow: 0px 0px 10px gray;
                border: 5px solid white;

                img {
                    width: $radius;
                    border-radius: 50%;
                }
            }

            .contacts {
                display: flex;
                column-gap: 20px;

                .contactIcons {
                    $bgColor: rgb(223, 223, 223);
                    background-color: $bgColor;
                    padding: 5px 5px 0px 5px;
                    border: 5px solid $bgColor;
                    border-radius: 50%;
                    cursor: pointer;

                    img {
                        width: 20px;
                    }
                }

            }
        }
    }

    .about {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        .aboutText {

            margin: 0px;
            font-size: 14px;
        }

        h3 {
            margin: 0px;
        }

    }

    .additionalInfo {
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 20px;
        width: 100%;
        column-gap: 10%;

        .elm {
            display: flex;
            flex-direction: column;
            row-gap: 6px;

            .title {
                font-weight: bold;
            }

            .value {
                font-size: 14px;
            }
        }

    }
}