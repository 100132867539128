.popUpDelete {
  box-shadow: 1px 1px 100px rgb(101, 101, 101);
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  z-index: 1;
  top: 10%;
  left: 25%;
  align-items: center;
  width: 320px;
  text-align: center;
  border-radius: 10px;
  padding: 10px 20px 20px 20px;
  animation: popupBasicDetails 0.7s forwards;
}
.popUpDelete .closeBtn {
  align-self: flex-end !important;
  cursor: pointer;
}
.popUpDelete .dangerSign {
  width: 50%;
}
.popUpDelete .cancel-delete-container {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.popUpDelete .cancel-delete-container .deleteBtn, .popUpDelete .cancel-delete-container .cancelBtn {
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
  cursor: pointer;
}
.popUpDelete .cancel-delete-container .cancelBtn {
  background-color: #557573;
  color: white;
  transition: all 0.5s;
}
.popUpDelete .cancel-delete-container .cancelBtn:hover {
  background-color: #89aeac;
  color: #453f3f;
  box-shadow: 1px 1px 10px gray;
}
.popUpDelete .cancel-delete-container .deleteBtn {
  background-color: #cc0707;
  color: white;
  transition: all 0.5s;
}
.popUpDelete .cancel-delete-container .deleteBtn:hover {
  background-color: #ff1414;
  color: #ffffff;
  box-shadow: 1px 1px 10px gray;
}

@keyframes popupBasicDetails {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}/*# sourceMappingURL=PopUpDelete.css.map */