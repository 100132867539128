.ve-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(186, 185, 185, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.ve-overlay .ve-bg {
  display: flex;
  flex-direction: row;
  gap: 15px;
  background: #ededec;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}
.ve-overlay .ve-bg .ve-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  width: 50rem;
  height: 30rem;
  padding: 10px 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.ve-overlay .ve-bg .ve-container .ve-border {
  border-bottom: 1px solid rgb(0, 0, 0);
  margin: 15px;
}
.ve-overlay .ve-bg .ve-container .ve-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.ve-overlay .ve-bg .ve-container .ve-title .ve-color::before {
  content: "ss";
  background-color: red;
  border: 5px solid red;
  color: red;
  border-radius: 50px;
}
.ve-overlay .ve-bg .ve-container .ve-time-date {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}
.ve-overlay .ve-bg .ve-container .ve-time-date img {
  padding-top: 10px;
  width: 30px;
}
.ve-overlay .ve-bg .ve-container .ve-location {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.ve-overlay .ve-bg .ve-container .ve-location img {
  width: 30px;
}
.ve-overlay .ve-bg .ve-container .ve-details {
  display: flex;
  flex-direction: column;
}
.ve-overlay .ve-bg .ve-container .ve-details .ve-detail-title {
  color: #0078D4;
  margin-right: auto;
  border-bottom: 3px solid #0078D4;
}
.ve-overlay .ve-bg .ve-container .ve-details .ve-detail {
  margin: 15px;
}
.ve-overlay .ve-bg .ve-invitees {
  background-color: white;
  width: 15rem;
  height: auto;
  border-radius: 8px;
  padding: 10px 20px;
}
.ve-overlay .ve-bg .ve-invitees .ve-CloseBtn {
  position: relative;
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bolder;
  cursor: pointer;
  transform: translate(13rem);
  padding: 10px;
}
.ve-overlay .ve-bg .ve-invitees .ve-invitees-title {
  color: rgb(0, 0, 0);
}
.ve-overlay .ve-bg .ve-invitees .ve-people {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}/*# sourceMappingURL=ViewEvent.css.map */