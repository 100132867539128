.Teachers {
  display: flex;
  flex-direction: column;
  height: 95vh;
  width: -moz-fit-content;
  width: fit-content;
}
.Teachers .gridInfoCard {
  display: flex;
  flex-direction: row;
  height: 70%;
  gap: 15px;
}
.Teachers .gridInfoCard .grid {
  display: flex;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  row-gap: 16px;
}/*# sourceMappingURL=AdminTeacherSec.css.map */