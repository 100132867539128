.EventList {
    position: relative;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;

    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        margin: 8px 0px 0px 0px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555555;
    }

    .EventItem-Container {
        border-bottom: 1px solid #e2e2e2;
        padding: 10px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .EventItem-Date {
            font-weight: normal;
            color: #2b2b2b;
            font-size: 0.8rem;
        }

        .EventItems {
            display: flex;
            flex-direction: row;
            gap: 15px;
            align-items: flex-start;
            justify-items: center;
            margin-top: 10px;

            .EventItem-Divider {
                content: "";
                position: relative;
                width: 0px;
                height: 45px;
                border-left: 5px solid red;
                border-radius: 50px;
            }

            .EventItem-Time {
                display: flex;
                flex-direction: column;
                color: #666;

                .EventItem-Duration {
                    font-weight: normal;
                }
            }


            .EventItem-Details {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .EventItem-Title {
                    font-size: 1rem;
                    color: #000000;
                    font-weight: bold;
                }

                .EventItem-Link {
                    color: #666;
                    text-decoration: none;
                    font-size: 0.8rem;
                }
            }
        }
    }
}