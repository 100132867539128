.PopUpEditAdd {
    position: absolute;
    box-shadow: 0px 0px 10px #353434;
    width: 560px;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    left: 15%;
    top: 0%;
    z-index: 1;

    .closeIcon {
        width: 30px;
        align-self: flex-end;
        cursor: pointer;
    }

    .fields {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;

        label {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            span {
                font-family: "Inter";
            }

            %commonInputLayout {
                border-radius: 10px;
                padding: 0px 0px 0px 10px;
                height: 35px;
                outline: none;
                border: 1px solid gray;
            }

            input[type='text'] {
                @extend %commonInputLayout;
            }

            input[type='number'] {
                @extend %commonInputLayout;
            }

            input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type='date'] {
                @extend %commonInputLayout;
                width: 160px;
            }

            .knob {
                $circleDim: 30px;
                width: $circleDim*2.5;
                height: $circleDim+7;
                box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.8);
                border-radius: $circleDim;
                position: relative;
                transition: all 0.7s;

                .circle {
                    width: $circleDim;
                    position: absolute;
                    height: $circleDim;
                    border-radius: 50%;
                    top: 1.8px;
                    left: 2px;
                    box-shadow: 0px 0px 2px rgb(54, 54, 54);
                    background-color: rgb(238, 235, 235);
                    border: 2px solid white;
                    transition: all 0.5s;
                    z-index: 1;
                    cursor: pointer;
                }
            }
        }

        .FileInput {
            display: flex;
            flex-direction: row;
            width: fit-content;
            column-gap: 10px;
            align-items: center;

            img {
                max-width: 60px;
            }

            %commonAddDel {
                padding: 8px 20px 10px 20px;
                border-radius: 10px;
                cursor: pointer
            }

            .addImg {
                @extend %commonAddDel;
                background-color: #2C7F75;
                color: white;
            }

            .delImg {
                @extend %commonAddDel;
                color: rgb(77, 77, 77);
                background-color: transparent;
                border: 2px solid rgb(190, 190, 190);
            }
        }

        .SubField {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            .SubFields {
                display: flex;
                flex-wrap: wrap;
                column-gap: 10px;
            }
        }


    }

    .saveDiscard {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        column-gap: 10px;
        padding-top: 20px;

        %commonBtnlayout {
            padding: 10px 30px 10px 30px;
            border-radius: 10px;
            cursor: pointer;
        }

        .save {
            @extend %commonBtnlayout;
            background-color: #2C7F75;
            color: white;
            border: 1px solid transparent;
            transition: all 0.7s;
        }

        .save:hover {
            background-color: #1a524c;
        }


        .discard {
            @extend %commonBtnlayout;
            background-color: transparent;
            border: 1px solid gray;
            transition: all 0.7s;
        }

        .discard:hover {
            background-color: rgb(222, 222, 222);
            border: 1px solid rgb(222, 222, 222);
        }
    }
}