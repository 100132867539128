.filter-container {
    .filter-options {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        top: 25%;
        left: 25%;
        border-radius: 10px;
        background-color: white;
        padding: 0px 8px 0px 8px;
        box-shadow: 1px 1px 100px rgb(101, 101, 101);
        z-index: 1;
        width: 52vh;
        height: 72vh;
        gap: 8px;
        overflow: scroll;

        .close-filter-container {
            display: flex;
            flex-direction: row;
            margin: 14px 8px 0px 0px;
            justify-content: flex-end;

            img {
                width: 24px;
                margin-bottom: 0px;
                cursor: pointer;
            }
        }



        .filter-titles {
            font-size: 12px;
        }

        .filter-by-container,
        .filterItem {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;
            border-bottom: 1px solid #8A8DAC;
        }

        .filter-by-data {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;
            padding-bottom: 18px;
        }

        .filter-by-data button {
            color: #3C4177;
            background-color: transparent;
            border: 1px solid #8A8DAC;
            border-radius: 5px;
            padding: 5px;
        }


        .done-container {
            display: flex;
            flex-direction: row;
            margin: 14px 8px 0px 0px;
            justify-content: flex-end;

            .done-button {
                color: #ffffff;
                font-size: 14px;
                font-weight: bold;
                background-color: #3C4177;
                border: 1px solid;
                border-radius: 5px;
                padding: 8px;
            }
        }

        .selected-item button {
            background-color: #006576;
            color: #FFFFFF;
        }
    }


}