.SideBarSettings {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 30px 0px 10px;
  border-right: 1px solid rgb(165, 165, 165);
}
.SideBarSettings .optionClicked, .SideBarSettings .option {
  display: flex;
  align-items: center;
  -moz-column-gap: 20px;
       column-gap: 20px;
  cursor: pointer;
  padding: 8px;
  transition: all 0.7s;
}
.SideBarSettings .optionClicked span, .SideBarSettings .option span {
  font-size: 16px;
}
.SideBarSettings .optionClicked img, .SideBarSettings .option img {
  width: 30px;
}
.SideBarSettings .optionClicked {
  background-color: rgba(37, 135, 201, 0.3411764706);
}
.SideBarSettings .optionClicked span {
  font-weight: 600;
  color: #0099ff;
}
.SideBarSettings .optionClicked img {
  filter: invert(50%) sepia(100%) saturate(400%) hue-rotate(180deg) brightness(100%) contrast(100%);
}/*# sourceMappingURL=SideBarSettings.css.map */