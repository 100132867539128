.SideBarSettings {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 10px 30px 0px 10px;
    border-right: 1px solid rgb(165, 165, 165);

    %commonDesign {
        display: flex;
        align-items: center;
        column-gap: 20px;
        cursor: pointer;
        padding: 8px;

        transition: all 0.7s;
        span {
            font-size: 16px;
        }

        img {

            width: 30px;
        }
    }

    .option {
        @extend %commonDesign;
    }

    .optionClicked {
        @extend %commonDesign;
        background-color: #2587c957;

        span {
            font-weight: 600;
            color: #0099ff;
        }
        img {
            filter: invert(50%) sepia(100%) saturate(400%) hue-rotate(180deg) brightness(100%) contrast(100%);
        }
    }
}