.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    column-gap: 10px;
    align-self: flex-end;

    button {
        background-color: transparent;
        color: #929292;
        border: 1px solid #929292;
        border-radius: 50%;
        padding: 8px 12px 8px 12px;
        cursor: pointer;
    }

    button.active {
        border: none;
        background-color: #6955F9;
        color: #ffffff;
    }
    
    button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}