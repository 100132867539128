.Billing {
  width: 100%;
  font-family: "Inter";
}
.Billing .title {
  border-bottom: 1px solid #b8b8b8;
  padding: 0px 0px 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.Billing .title h2 {
  margin: 0;
}
.Billing .title span {
  color: #868686;
}
.Billing .options .historyCancel {
  display: flex;
  justify-content: space-between;
}
.Billing .options .payementMethRecurence {
  display: flex;
  justify-content: space-between;
}/*# sourceMappingURL=Billing.css.map */