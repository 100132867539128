.AdminStudentDetails {
  box-shadow: 1px 1px 10px rgb(105, 105, 105);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 16px;
  font-family: "Inter";
}
.AdminStudentDetails .UpperSec {
  display: flex;
  background-color: #006676;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 10px;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: center;
}
.AdminStudentDetails .UpperSec img {
  border-radius: 50%;
  width: 60px;
}
.AdminStudentDetails .UpperSec .shortDescript {
  color: white;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.AdminStudentDetails .UpperSec .shortDescript .studentName {
  font-size: 20px;
}
.AdminStudentDetails .UpperSec .shortDescript .class {
  font-size: 12px;
  font-size: light;
}
.AdminStudentDetails .infoSection {
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 75%;
}
.AdminStudentDetails .infoSection .BasicDetails {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0px 16px 8px 16px;
  border: 2px solid rgb(221, 221, 221);
  border-radius: 10px;
}
.AdminStudentDetails .infoSection .BasicDetails .studentAdditionalDetails, .AdminStudentDetails .infoSection .BasicDetails .studentDetails {
  display: flex;
  align-items: center;
}
.AdminStudentDetails .infoSection .BasicDetails .studentAdditionalDetails .DetailBlock, .AdminStudentDetails .infoSection .BasicDetails .studentDetails .DetailBlock {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 150px;
}
.AdminStudentDetails .infoSection .BasicDetails .studentAdditionalDetails .DetailBlock .blockName, .AdminStudentDetails .infoSection .BasicDetails .studentDetails .DetailBlock .blockName {
  color: rgb(163, 163, 163);
  font-size: 12px;
}
.AdminStudentDetails .infoSection .BasicDetails .studentAdditionalDetails .DetailBlock .blockData, .AdminStudentDetails .infoSection .BasicDetails .studentDetails .DetailBlock .blockData {
  color: #006676;
  font-weight: bold;
  font-size: 13px;
}
.AdminStudentDetails .infoSection .BasicDetails .studentDetails {
  -moz-column-gap: 80px;
       column-gap: 80px;
}
.AdminStudentDetails .infoSection .BasicDetails .studentAdditionalDetails {
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.AdminStudentDetails .infoSection .BasicDetails .studentAdditionalDetails .DetailBlock .blockData {
  max-width: 100%;
  /* Set your desired width */
  overflow: hidden;
  /* Hide overflow text */
  white-space: nowrap;
  /* Prevent text wrapping */
  text-overflow: ellipsis;
}
.AdminStudentDetails .infoSection .BasicDetails .Title {
  color: #006676;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  height: 40px;
}
.AdminStudentDetails .infoSection .BasicDetails .Title .edit-delete-container {
  display: flex;
  justify-self: flex-end;
}
.AdminStudentDetails .infoSection .BasicDetails .Title .edit-delete-container img {
  cursor: pointer;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec {
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 25%;
  border-radius: 10px;
  background-color: white;
  padding: 0px 20px 20px 20px;
  box-shadow: 1px 1px 100px rgb(101, 101, 101);
  animation: popupBasicDetails 1s forwards;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .upperContainer {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  align-items: center;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .upperContainer img {
  justify-self: flex-end;
  cursor: pointer;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .right-side-1-container, .AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .middle-side-container, .AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .left-side-container {
  width: 200px;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .right-side-1-container, .AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .middle-side-container, .AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .left-side-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .right-side-1-container label, .AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .middle-side-container label, .AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .left-side-container label {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .right-side-1-container label input, .AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .middle-side-container label input, .AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .left-side-container label input {
  border-radius: 10px;
  border: 1px solid #c0c0c0;
  height: 30px;
  padding: 0px 10px 0px 10px;
  background-color: #e6e6e6;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .right-side-1-container label input:focus, .AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .middle-side-container label input:focus, .AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .left-side-container label input:focus {
  background-color: white;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .middle-side-container label .classCompletion {
  background-color: rgb(1, 220, 187);
  width: 68px;
  height: 34px;
  border-radius: 20px;
  position: relative;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .middle-side-container label .classCompletion .switch {
  background-color: rgb(239, 239, 239);
  height: 27px;
  width: 27px;
  top: 2px;
  border-radius: 50%;
  position: absolute;
  box-shadow: 1px 1px 5px gray;
  border: 2px solid white;
  cursor: pointer;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .middle-side-container label .classCompletion .switchText {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 8px;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .right-side-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .right-side-container .choose-Drop-and-Drag-img {
  border: 1px dashed gray;
  border-radius: 10px;
  width: inherit;
  padding: 50px 25px 50px 25px;
  cursor: pointer;
  transition: all 0.5s;
  color: #c0c0c0;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .right-side-container .choose-Drop-and-Drag-img span {
  display: block;
  width: 150px;
  text-align: center;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .fields .right-side-container .choose-Drop-and-Drag-img:hover {
  background-color: #e7e7e7;
  color: rgb(114, 114, 114);
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .update-btn {
  margin-top: 20px;
  background-color: #006676;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 20px 10px 20px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpEditSec .update-btn:hover {
  background-color: #0097ae;
  box-shadow: 1px 1px 10px gray;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpDeleteSec {
  box-shadow: 1px 1px 100px rgb(101, 101, 101);
  flex-direction: column;
  background-color: white;
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 40%;
  align-items: center;
  width: 320px;
  text-align: center;
  border-radius: 10px;
  padding: 10px 20px 20px 20px;
  animation: popupBasicDetails 0.7s forwards;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpDeleteSec .closeBtn {
  align-self: flex-end !important;
  cursor: pointer;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpDeleteSec .dangerSign {
  width: 50%;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpDeleteSec .cancel-delete-container {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpDeleteSec .cancel-delete-container .deleteBtn, .AdminStudentDetails .infoSection .BasicDetails .popUpDeleteSec .cancel-delete-container .cancelBtn {
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
  cursor: pointer;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpDeleteSec .cancel-delete-container .cancelBtn {
  background-color: #557573;
  color: white;
  transition: all 0.5s;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpDeleteSec .cancel-delete-container .cancelBtn:hover {
  background-color: #89aeac;
  color: #453f3f;
  box-shadow: 1px 1px 10px gray;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpDeleteSec .cancel-delete-container .deleteBtn {
  background-color: #cc0707;
  color: white;
  transition: all 0.5s;
}
.AdminStudentDetails .infoSection .BasicDetails .popUpDeleteSec .cancel-delete-container .deleteBtn:hover {
  background-color: #ff1414;
  color: #ffffff;
  box-shadow: 1px 1px 10px gray;
}
@keyframes popupBasicDetails {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph {
  border: 2px solid rgb(221, 221, 221);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-height: 200px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions {
  display: grid;
  grid-template-rows: auto auto;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .options {
  display: flex;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .options .option {
  padding: 10px;
  background-color: rgb(218, 218, 218);
  cursor: pointer;
  color: #919191;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame {
  display: flex;
  justify-self: flex-end;
  margin: 8px 20px 8px 0px;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec, .AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec {
  padding: 6px 7px 8px 7px;
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid rgb(111, 111, 111);
  cursor: pointer;
  transition: all 0.5s;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec:hover, .AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec:hover {
  background-color: rgb(111, 111, 111);
  color: white;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec .years, .AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec .months {
  display: none;
  z-index: 1;
  position: absolute;
  background-color: rgb(255, 255, 255);
  top: 28px;
  left: 0;
  max-height: 90px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  box-shadow: 1px 1px 10px black;
  /* Custom scrollbar styles */
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec .years::-webkit-scrollbar, .AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec .months::-webkit-scrollbar {
  width: 8px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec .years::-webkit-scrollbar-track, .AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec .months::-webkit-scrollbar-track {
  background-color: transparent;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec .years::-webkit-scrollbar-thumb, .AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec .months::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec .years .year, .AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec .months .month {
  padding: 7px 0px 7px 0px;
  border-bottom: 1px solid rgb(158, 158, 158);
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  transition: all 0.5s;
  color: rgb(102, 102, 102);
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec {
  position: relative;
  text-align: center;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec .months .month {
  width: 70px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec .months .month:hover {
  background-color: gray;
  color: white;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .monthSec:hover .months {
  display: flex;
  flex-direction: column;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec {
  position: relative;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec .years .year {
  width: 45px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec .years .year:hover {
  background-color: gray;
  color: white;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .TimeFrame .yearSec:hover .years {
  display: flex;
  flex-direction: column;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .courseSelector {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding: 4px;
  max-width: 500px;
  overflow-x: auto;
  margin-bottom: 5px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .courseSelector::-webkit-scrollbar {
  height: 10px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .courseSelector::-webkit-scrollbar-track {
  background-color: transparent;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .courseSelector::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .courseSelector::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .courseSelector table {
  border-collapse: collapse;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .courseSelector .course {
  padding: 8px;
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid rgb(111, 111, 111);
  cursor: pointer;
  transition: all 0.6s;
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .courseSelector .course .dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .GraphOptions .courseSelector .course:hover {
  background-color: rgb(192, 192, 192);
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .custom-tooltip {
  background-color: white;
  box-shadow: 1px 1px 10px gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .custom-tooltip .label {
  font-size: 12px;
  background-color: #006676;
  color: white;
  padding: 8px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.AdminStudentDetails .infoSection .AttendanceGradeGraph .custom-tooltip .data {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  padding: 0px 8px 10px 8px;
  text-align: center;
}/*# sourceMappingURL=AdminStudentDetails.css.map */