.CalendarComp {
  display: flex;
  flex-direction: column;
}
.CalendarComp .Calendar {
  border-radius: 10px;
  box-shadow: 0px 0px 6px #666666;
  display: flex;
  background-color: #f8f9f9;
  width: -moz-fit-content;
  width: fit-content;
}
.CalendarComp .Calendar .DayColumn h3 {
  text-align: center;
  font-size: inherit;
  font-weight: bold;
  color: #666666;
  margin: 0;
  padding: 8px 0px 8px 0px;
}
.CalendarComp .Calendar .DayColumn .listOfDays {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CalendarComp .Calendar .DayColumn .listOfDays .DayBlock {
  position: relative;
  width: 110px;
  height: 90px;
  border-left: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  background-color: #feffff;
}
.CalendarComp .Calendar .DayColumn .listOfDays .DayBlock .EventCard {
  display: flex;
  -moz-column-gap: 6px;
       column-gap: 6px;
  cursor: pointer;
  align-items: center;
  width: 90%;
  height: 24px;
  color: white;
  text-align: center;
  position: relative;
  border-radius: 6px;
  margin: 36px 0px 0px 0px;
  padding: 0px 6px 0px 2px;
  background-color: #0078d5;
}
.CalendarComp .Calendar .DayColumn .listOfDays .DayBlock .EventCard .title {
  width: 70px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: inherit;
  white-space: nowrap;
}
.CalendarComp .Calendar .DayColumn .listOfDays .DayBlock .EventCard .whiteRect {
  width: 8px;
  height: 80%;
  background-color: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.CalendarComp .Calendar .DayColumn .listOfDays .DayBlock .EventCard img {
  width: 15px;
}
.CalendarComp .Calendar .DayColumn .listOfDays .DayBlock .day {
  position: absolute;
  left: 10px;
  top: 8px;
  padding: 1px 0px 2px 0px;
  width: 24px;
  text-align: center;
  color: #666666;
  font-weight: bold;
}/*# sourceMappingURL=Calendar.css.map */