.Settings {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .sideBarSettingView {
        display: flex;
        box-shadow: 0px 0px 10px #a1a1a1;
        padding: 20px;
        height: 100%;
        column-gap: 20px;
    }
}