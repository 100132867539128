.role-change-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.role-change-overlay .role-change-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}
.role-change-overlay .role-change-box h3 {
  margin-bottom: 20px;
}
.role-change-overlay .role-change-box .roles-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.role-change-overlay .role-change-box .roles-list .role-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.role-change-overlay .role-change-box .roles-list .role-item input {
  margin-right: 10px;
}
.role-change-overlay .role-change-box .role-change-buttons {
  display: flex;
  justify-content: space-between;
}
.role-change-overlay .role-change-box .role-change-buttons button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  border: none;
}
.role-change-overlay .role-change-box .role-change-buttons button:hover {
  opacity: 0.8;
}
.role-change-overlay .role-change-box .role-change-buttons .save-button {
  background-color: #28a745;
  color: white;
}/*# sourceMappingURL=RoleChange.css.map */