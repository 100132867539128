.Admin {
  display: flex;
  -moz-column-gap: 12px;
       column-gap: 12px;
  padding: 10px;
  width: 98.65%;
  height: 97.2vh;
}
.Admin p,
.Admin span,
.Admin tr,
.Admin div > span,
.Admin button,
.Admin a {
  font-size: var(--font-size-bodyText) !important;
}
.Admin h1,
.Admin h2,
.Admin h3 {
  font-size: var(--font-size-title-cat1) !important;
}
.Admin h4,
.Admin h5 {
  font-size: var(--font-size-title-cat2) !important;
}/*# sourceMappingURL=Admin.css.map */