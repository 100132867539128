.RecurrentPayement {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.RecurrentPayement .Title {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.RecurrentPayement .Title h4 {
  margin: 0;
}
.RecurrentPayement .Title span {
  color: gray;
}
.RecurrentPayement .knob {
  width: 75px;
  height: 37px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.8);
  border-radius: 30px;
  position: relative;
  transition: all 0.7s;
}
.RecurrentPayement .knob .circle {
  width: 30px;
  position: absolute;
  height: 30px;
  border-radius: 50%;
  top: 1.8px;
  left: 2px;
  box-shadow: 0px 0px 2px rgb(54, 54, 54);
  background-color: rgb(238, 235, 235);
  border: 2px solid white;
  transition: all 0.5s;
  z-index: 1;
  cursor: pointer;
}
.RecurrentPayement .knob .knobState {
  position: absolute;
  top: 9px;
  color: white;
  transition: all 0.7s;
}/*# sourceMappingURL=RecurrentPayement.css.map */