.PayementMethod {

    .title {
        padding: 0px 0px 10px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        border: none;
        width: fit-content;

        h4 {
            margin: 0;
        }

        span {
            color: #868686;
        }
    }

    .payementMethodsAvailable {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        overflow-y: auto;
        height: 180px;
        width: fit-content;

        .method {
            display: flex;
            align-items: center;
            width: 520px;
            padding: 0px 20px 0px 20px;

            .payementLogo {

                img {
                    width: 60px;
                }
            }

            .info {
                justify-self: flex-start;
                flex-grow: 1;
                padding: 0px 0px 0px 10px;
            }

            button {
                background-color: white;
                border: 1px solid #868686;
                padding: 10px 16px 10px 16px;
                border-radius: 10px;
                outline: none;
                cursor: pointer;
            }
        }
    }

    .addPaymMethod {
        display: flex;
        align-items: center;
        column-gap: 30px;
        margin: 10px 0px 0px 20px;

        .add {
            $circleSize: 50px;
            width: $circleSize;
            height: $circleSize;
            box-shadow: 0px 0px 10px #868686;
            border-radius: 50%;
            cursor: pointer;

            img {
                width: $circleSize;
                color: #868686;

            }

            transition: all 0.5s;

            &:hover {
                box-shadow: 0px 0px 10px #050505;
            }
        }
    }
}