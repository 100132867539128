$bgColor: #d7d6d6;
$textColor: #4b4b4b;

.CalendarNavigation {
    width: 100%;
    display: flex;
    align-items: center;
    color: $textColor;
    padding: 10px 0px 18px;
    .todayBtn {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;

        img {
            width: 20px;
        }

        cursor: pointer;
    }

    .monthNavigation {
        flex-grow: 2;
        cursor: pointer;

        .innerDiv {
            display: flex;
            align-items: center;
            column-gap: 10px;
            width: fit-content;
            margin: 0px auto 0px auto;

            h1 {
                width: 200px;
                text-align: center;
                margin: 0px;
            }

            img {
                width: 16px;
                cursor: pointer;
            }
        }

    }

    .yearMonthNav {
        cursor: pointer;
        display: flex;
        column-gap: 10px;
        position: relative;

        %scrollerDesign {

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                margin: 10px 10px 10px 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #888888;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #555555;
            }
        }

        .yearSelection {
            width: fit-content;
            background-color: white;
            position: absolute;
            box-shadow: 0px 0px 10px gray;
            z-index: 1;
            display: flex;
            flex-wrap: wrap;
            border-radius: 10px;

            .backFrontNav {
                img {
                    width: 16px;
                }

                padding: 10px 0px 10px 10px;
                column-gap: 10px;
                width: 100%;
                display: flex;
                align-items: center;
            }

            .option {
                border-radius: 10px;
                font-size: 12px;
                padding: 10px 10px 10px 10px;

                &:hover {
                    background-color: #888888;
                    color: white;
                }
            }
        }

        .navYearOrMonth {
            position: absolute;
            background-color: white;
            box-shadow: 0px 0px 10px gray;
            width: 60%;
            top: 0;
            left: 32px;
            border-radius: 10px;

            .option {
                text-align: center;
                padding: 10px 0px 10px 0px;
                font-weight: bold;
                font-size: 12px;

                &:hover {
                    background-color: #888888;
                    color: white;
                }
            }
        }

        img {
            width: 20px;
        }

        .monthList {
            z-index: 1;
            position: absolute;
            left: -10px;
            display: flex;
            flex-wrap: wrap;
            width: 160px;
            height: 120px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px gray;
            background-color: white;
            overflow-y: auto;

            @extend %scrollerDesign;

            .month {
                padding: 10px;
                cursor: pointer;

                &:hover {
                    background-color: $bgColor;
                }
            }
        }
    }
}