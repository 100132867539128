.PayementMethod .title {
  padding: 0px 0px 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
}
.PayementMethod .title h4 {
  margin: 0;
}
.PayementMethod .title span {
  color: #868686;
}
.PayementMethod .payementMethodsAvailable {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow-y: auto;
  height: 180px;
  width: -moz-fit-content;
  width: fit-content;
}
.PayementMethod .payementMethodsAvailable .method {
  display: flex;
  align-items: center;
  width: 520px;
  padding: 0px 20px 0px 20px;
}
.PayementMethod .payementMethodsAvailable .method .payementLogo img {
  width: 60px;
}
.PayementMethod .payementMethodsAvailable .method .info {
  justify-self: flex-start;
  flex-grow: 1;
  padding: 0px 0px 0px 10px;
}
.PayementMethod .payementMethodsAvailable .method button {
  background-color: white;
  border: 1px solid #868686;
  padding: 10px 16px 10px 16px;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
}
.PayementMethod .addPaymMethod {
  display: flex;
  align-items: center;
  -moz-column-gap: 30px;
       column-gap: 30px;
  margin: 10px 0px 0px 20px;
}
.PayementMethod .addPaymMethod .add {
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 10px #868686;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
}
.PayementMethod .addPaymMethod .add img {
  width: 50px;
  color: #868686;
}
.PayementMethod .addPaymMethod .add:hover {
  box-shadow: 0px 0px 10px #050505;
}/*# sourceMappingURL=PayementMethod.css.map */