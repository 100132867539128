.PaymentMethodAdd {
    position: absolute;
    top: 15%;

    box-shadow: 0px 0px 10px gray;
    padding: 10px 20px 20px 20px;
    border-radius: 10px;
    width: 500px;
    background-color: white;
    .upperSec {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            display: flex;
            flex-direction: column;

            span {
                color: gray;
                font-size: 13px;
                font-weight: lighter;
            }
        }

        img {
            cursor: pointer;
        }
    }

    .methodFields {
        .method {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            border-bottom: 1px solid rgb(218, 218, 218);
            cursor: pointer;

            img {
                width: 100px;
            }

            h4 {
                display: flex;
                column-gap: 10px;
                align-items: center;

                .radioBtn {
                    $dimension: 20px;
                    height: $dimension;
                    width: $dimension;
                    border-radius: 50%;
                    border: 2px solid gray;

                    .knob {
                        height: $dimension - 11;
                        width: $dimension - 11;
                        margin: 0.5px 0px 0px 0.5px;
                        border-radius: 50%;
                        border: 5px solid #00878b;
                    }
                }
            }
        }
       
        .CreditCard {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            margin-top: 20px;

            .cardnumber {
                padding: 10px;
                outline: none;
                font-size: medium;
            }

            .backCard {
                display: flex;
                justify-content: space-between;

                input {
                    padding: 10px;
                    width: 100px;
                    outline: none;
                    font-size: medium;
                }
            }

            .name {
                width: 100%;
                display: flex;
                column-gap: 20px;
              
                justify-content: space-between;

                label {
                    color: gray;
                    font-size: 13px;
                }

                input {
                    border: 1px solid gray;
                    width: 95%;
                    height: 35px;
                    outline: none;
                    font-size: medium;
                    padding: 0px 0px 0px 10px;
                }
            }
        }
    }

    button {
        width: 100%;
        background-color: #00878b;
        color: white;
        font-family: "Inter";
        border: none;
        padding: 16px 0px 16px 0px;
        font-size: medium;
        transition: all 0.6s;
        cursor: pointer;

        &:hover {
            background-color: #03b0b6;
        }
    }
}