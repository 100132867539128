@import url('https://fonts.googleapis.com/css?family=Inter');

.student-list-container {
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 75vh;

    .student-list-search {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
        border-bottom: 2px solid #C6C6C6;

        .filter-container {
            display: flex;
            flex-direction: row;
            gap: 5px;
            margin-right: 5px;
            font-size: 14px;
            color: #212765;
            font-family: Inter;
            cursor: pointer;

            img {
                width: 14px;
                margin: 0px 0px 0px 15px;
            }
        }

        .search-bar-wrapper {
            width: 100%;
            display: flex;
            padding: 8px 10px 8px 0px;

            .search-bar {
                background-image: url('../../../icons/searchIcon.svg');
                background-position: 5px;
                background-size: 2vh;
                padding: 10px 10px 10px 35px;
                background-repeat: no-repeat;
                outline: none;
                border: 1px solid #6d6d6d;
                border-radius: 10px;
                flex-grow: 1;
            }
        }

    }






    .selected-filter {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        padding: 5px 10px 0px;

        button {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            background-color: #006576;
            color: #FFFFFF;
            border: 1px solid;
            border-radius: 5px;
            padding: 5px;

        }

        .close-filter {
            cursor: pointer;
            height: 18px;
            width: 18px;
        }
    }




    .filter img:hover {
        fill: red;
    }

    .student-table {
        border: 1px solid #FFFFFF;
        border-radius: 15px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
            margin-top: 60px;
            margin-bottom: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888888;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #555555;
        }

        table {
            border-collapse: collapse;
        }



        tr {
            %commonThTd {
                width: 150px;
                text-align: center;
                padding: 5px;
                cursor: pointer;
            }

            p {
                cursor: pointer;
            }

            th {
                @extend %commonThTd;
                position: sticky;
                text-align: center;
                font-size: 12px;
                font-weight: bold;
                color: #C6C6C6;
            }

            td {
                @extend %commonThTd;
                text-align: center;
                border-bottom: 1px solid #C6C6C6;
                font-size: 12px;
                padding: 15px;

                img {
                    border: 1px solid #C6C6C6;
                    border-radius: 50%;
                    width: 6vh;
                    height: 6vh;
                }
            }
        }


        tbody :hover {
            background-color: #006576;
            color: #FFFFFF;
        }





        .checked-color {
            background-color: #006576;
            color: #FFFFFF;

           
        }

        .individualCheckBox {
            cursor: pointer;
        }

    }
}