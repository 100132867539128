.teachers-list-container {
  display: flex;
  flex-wrap: wrap;
  height: -moz-fit-content;
  height: fit-content;
  gap: 15px;
  flex-wrap: wrap;
}
.teachers-list-container .teachers-list-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  -moz-column-gap: 15px;
       column-gap: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgb(172, 172, 172);
  border-radius: 10px;
  padding: 0px 20px;
  width: 230px;
  height: 160px;
}
.teachers-list-container .teachers-list-grid .teachers-profile-pic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px gray;
}
.teachers-list-container .teachers-list-grid .teachers-profile-pic img {
  width: 80px;
  border-radius: 50%;
}
.teachers-list-container .teachers-list-grid .teachers-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: -moz-fit-content;
  width: fit-content;
}
.teachers-list-container .teachers-list-grid .teachers-info .teachers-fullname h3 {
  margin: 10px;
  font-weight: 600;
}
.teachers-list-container .teachers-list-grid .teachers-info .teachers-disciplines {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 60px;
  /* Set max height */
  overflow: hidden;
}
.teachers-list-container .teachers-list-grid .teachers-info .teachers-disciplines .course {
  color: #929292;
  font-weight: 400;
  max-width: 150px;
  /* Set your desired width */
  overflow: hidden;
  /* Hide overflow text */
  white-space: nowrap;
  /* Prevent text wrapping */
  text-overflow: ellipsis;
}
.teachers-list-container .teachers-list-grid .teachers-info .teachers-profile-link {
  cursor: pointer;
}
.teachers-list-container .teachers-list-grid .teachers-info .teachers-profile-link p {
  font-size: 12px;
  font-weight: 500;
  color: blue;
}/*# sourceMappingURL=TeachersList.css.map */