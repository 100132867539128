.user-mgt-container {
    width: 100%;
    font-family: "Inter";
    max-height: 80vh;
    overflow-y: auto;

    .title {
        border-bottom: 1px solid #b8b8b8;
        padding: 0px 0px 10px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        h2 {
            margin: 0;
        }

        span {
            color: #868686;
        }
    }

    .add-member-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0px;
        padding: 15px 25px;
        background-color: #F2F2F2;
        border-radius: 5px;

        .add-member-title {
            h4 {
                margin: 0;
            }

            p {
                font-size: 14px;
                color: #868686;
            }
        }

    }

    .add-member-btn-container {
        padding-right: 15px;

        .add-member-btn {
            padding: 14px;
            border-radius: 5px;
            cursor: pointer;
            background-color: #2C7F75;
            color: #ffffff;
            border: none;
        }

        .invite-member-popup {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1000;
            // background-color: rgba(0, 0, 0, 0.5); /* Darken background */
            padding: 0;
            border-radius: 10px;

        }
    }

    .mgt-navbar-container {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #868686;
        border-radius: 5px;


        a {
            text-decoration: none;
            color: #868686;
            margin-left: 35%;
            
            
            // &.active {
            //     color: blue;
            // }
        }

        .acc-header-container {
            width: 50%;
            height: 35px;
            align-content: center;
            border-radius: 5px;
            border-right: 1px solid #868686;
            border-top-right-radius: 0%;
            border-bottom-right-radius: 0%;
            border-bottom-left-radius: 0%;
            cursor: pointer;
        }

        .user-header-container {
            width: 50%;
            height: 35px;
            align-content: center;
            border-radius: 5px;
            border-left: 1px solid #868686;
            border-top-left-radius: 0%;
            border-bottom-right-radius: 0%;
            border-bottom-left-radius: 0%;
            cursor: pointer;
        }

        .active {
            &.acc-header-container, &.user-header-container {
                background-color: #F2F2F2;
            }
            &.acc-header-container a, &.user-header-container a {
                color: #000000;
            }
        }
    }
    &::-webkit-scrollbar {
        width: 8px;
      }
    
      &::-webkit-scrollbar-track {
        background-color: transparent;
        margin: 10px;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: #888888;
        border-radius: 10px;
      }
    
      &::-webkit-scrollbar-thumb:hover {
        background-color: #555555;
      }
}