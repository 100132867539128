.Settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.Settings .sideBarSettingView {
  display: flex;
  box-shadow: 0px 0px 10px #a1a1a1;
  padding: 20px;
  height: 100%;
  -moz-column-gap: 20px;
       column-gap: 20px;
}/*# sourceMappingURL=Settings.css.map */