.PaymentMethodAdd {
  position: absolute;
  top: 15%;
  box-shadow: 0px 0px 10px gray;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  width: 500px;
  background-color: white;
}
.PaymentMethodAdd .upperSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PaymentMethodAdd .upperSec h3 {
  display: flex;
  flex-direction: column;
}
.PaymentMethodAdd .upperSec h3 span {
  color: gray;
  font-size: 13px;
  font-weight: lighter;
}
.PaymentMethodAdd .upperSec img {
  cursor: pointer;
}
.PaymentMethodAdd .methodFields .method {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgb(218, 218, 218);
  cursor: pointer;
}
.PaymentMethodAdd .methodFields .method img {
  width: 100px;
}
.PaymentMethodAdd .methodFields .method h4 {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.PaymentMethodAdd .methodFields .method h4 .radioBtn {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid gray;
}
.PaymentMethodAdd .methodFields .method h4 .radioBtn .knob {
  height: 9px;
  width: 9px;
  margin: 0.5px 0px 0px 0.5px;
  border-radius: 50%;
  border: 5px solid #00878b;
}
.PaymentMethodAdd .methodFields .CreditCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
}
.PaymentMethodAdd .methodFields .CreditCard .cardnumber {
  padding: 10px;
  outline: none;
  font-size: medium;
}
.PaymentMethodAdd .methodFields .CreditCard .backCard {
  display: flex;
  justify-content: space-between;
}
.PaymentMethodAdd .methodFields .CreditCard .backCard input {
  padding: 10px;
  width: 100px;
  outline: none;
  font-size: medium;
}
.PaymentMethodAdd .methodFields .CreditCard .name {
  width: 100%;
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
  justify-content: space-between;
}
.PaymentMethodAdd .methodFields .CreditCard .name label {
  color: gray;
  font-size: 13px;
}
.PaymentMethodAdd .methodFields .CreditCard .name input {
  border: 1px solid gray;
  width: 95%;
  height: 35px;
  outline: none;
  font-size: medium;
  padding: 0px 0px 0px 10px;
}
.PaymentMethodAdd button {
  width: 100%;
  background-color: #00878b;
  color: white;
  font-family: "Inter";
  border: none;
  padding: 16px 0px 16px 0px;
  font-size: medium;
  transition: all 0.6s;
  cursor: pointer;
}
.PaymentMethodAdd button:hover {
  background-color: #03b0b6;
}/*# sourceMappingURL=PaymentMethodAdd.css.map */