.AdminStudentDetails {

    box-shadow: 1px 1px 10px rgb(105, 105, 105);
    width: fit-content;
    border-radius: 16px;
    font-family: 'Inter';

    .UpperSec {
        display: flex;
        background-color: #006676;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        padding: 10px;
        column-gap: 20px;

        align-items: center;

        img {
            border-radius: 50%;
            width: 60px;
        }

        .shortDescript {
            color: white;
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            .studentName {
                font-size: 20px;
            }

            .class {
                font-size: 12px;
                font-size: light;
            }
        }
    }

    .infoSection {
        padding: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        height: 75%;

        .BasicDetails {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            padding: 0px 16px 8px 16px;
            border: 2px solid rgb(221, 221, 221);
            border-radius: 10px;

            %commonMainContainer {
                display: flex;
                align-items: center;
            }

            %commonDetailBlock {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                max-width: 150px;
            }

            %commonBlockName {
                color: rgb(163, 163, 163);
                font-size: 12px;
            }

            %commonblockData {
                color: #006676;
                font-weight: bold;
                font-size: 13px;
            }

            .studentDetails {
                @extend %commonMainContainer;
                column-gap: 80px;

                .DetailBlock {
                    @extend %commonDetailBlock;

                    .blockName {
                        @extend %commonBlockName;
                    }

                    .blockData {
                        @extend %commonblockData;
                        
                    }
                }

            }

            .studentAdditionalDetails {
                @extend %commonMainContainer;
                column-gap: 20px;

                .DetailBlock {
                    @extend %commonDetailBlock;

                    .blockName {
                        @extend %commonBlockName;
                    }

                    .blockData {
                        max-width: 100%;
                        /* Set your desired width */
                        overflow: hidden;
                        /* Hide overflow text */
                        white-space: nowrap;
                        /* Prevent text wrapping */
                        text-overflow: ellipsis;
                        @extend %commonblockData;
                    }
                }

            }

            .Title {
                color: #006676;
                display: grid;
                grid-template-columns: auto auto;
                align-items: center;
                height: 40px;

                .edit-delete-container {

                    display: flex;
                    justify-self: flex-end;

                    img {
                        cursor: pointer;
                    }
                }
            }

            .popUpEditSec {

                position: absolute;
                z-index: 1;
                top: 25%;
                left: 25%;
                border-radius: 10px;
                background-color: white;
                padding: 0px 20px 20px 20px;
                box-shadow: 1px 1px 100px rgb(101, 101, 101);
                animation: popupBasicDetails 1s forwards;

                .upperContainer {
                    display: grid;
                    grid-template-columns: auto auto;
                    width: 100%;
                    align-items: center;

                    img {
                        justify-self: flex-end;
                        cursor: pointer;
                    }

                }


                .fields {
                    display: flex;
                    column-gap: 20px;

                    %commonContainerWidth {
                        width: 200px;
                    }

                    %commonContainerDisplay {
                        display: flex;
                        flex-direction: column;
                        row-gap: 10px;
                    }

                    %commonLabelDisplay {
                        display: flex;
                        flex-direction: column;
                        row-gap: 5px;

                        input {
                            border-radius: 10px;
                            border: 1px solid #c0c0c0;
                            height: 30px;
                            padding: 0px 10px 0px 10px;
                            background-color: #e6e6e6;
                        }

                        input:focus {
                            background-color: white;
                        }

                    }

                    .left-side-container {

                        @extend %commonContainerWidth;
                        @extend %commonContainerDisplay;

                        label {
                            @extend %commonLabelDisplay;
                        }
                    }

                    .middle-side-container {
                        @extend %commonContainerWidth;
                        @extend %commonContainerDisplay;

                        label {
                            @extend %commonLabelDisplay;

                            .classCompletion {
                                background-color: rgb(1, 220, 187);
                                width: 68px;
                                height: 34px;
                                border-radius: 20px;
                                position: relative;

                                .switch {
                                    background-color: rgb(239, 239, 239);
                                    height: 27px;
                                    width: 27px;
                                    top: 2px;
                                    border-radius: 50%;
                                    position: absolute;
                                    box-shadow: 1px 1px 5px gray;
                                    border: 2px solid white;
                                    cursor: pointer;
                                }

                                .switchText {
                                    color: white;
                                    font-weight: bold;
                                    position: absolute;
                                    top: 8px;
                                }

                            }
                        }
                    }

                    .right-side-1-container {
                        @extend %commonContainerWidth;
                        @extend %commonContainerDisplay;

                        label {
                            @extend %commonLabelDisplay;
                        }
                    }

                    .right-side-container {

                        display: flex;
                        flex-direction: column;
                        row-gap: 8px;

                        .choose-Drop-and-Drag-img {
                            border: 1px dashed gray;
                            border-radius: 10px;
                            width: inherit;
                            padding: 50px 25px 50px 25px;
                            cursor: pointer;
                            transition: all 0.5s;
                            color: #c0c0c0;

                            span {
                                display: block;
                                width: 150px;
                                text-align: center;
                            }

                        }

                        .choose-Drop-and-Drag-img:hover {
                            background-color: #e7e7e7;
                            color: rgb(114, 114, 114);
                        }
                    }

                }

                .update-btn {
                    margin-top: 20px;
                    background-color: #006676;
                    width: fit-content;
                    padding: 10px 20px 10px 20px;
                    color: white;
                    border-radius: 10px;
                    cursor: pointer;
                    transition: all 0.5s;
                }

                .update-btn:hover {
                    background-color: #0097ae;
                    box-shadow: 1px 1px 10px gray;
                }

            }

            .popUpDeleteSec {
                box-shadow: 1px 1px 100px rgb(101, 101, 101);
                flex-direction: column;
                background-color: white;
                position: absolute;
                z-index: 1;
                top: 25%;
                left: 40%;
                align-items: center;
                width: 320px;
                text-align: center;
                border-radius: 10px;
                padding: 10px 20px 20px 20px;
                animation: popupBasicDetails 0.7s forwards;

                .closeBtn {
                    align-self: flex-end !important;
                    cursor: pointer;
                }

                .dangerSign {
                    width: 50%;
                }

                .cancel-delete-container {
                    display: flex;
                    column-gap: 20px;

                    %commonBtnDesign {
                        padding: 10px 20px 10px 20px;
                        border-radius: 6px;
                        cursor: pointer;
                    }

                    .cancelBtn {
                        background-color: #557573;
                        color: white;
                        @extend %commonBtnDesign;
                        transition: all 0.5s;
                    }

                    .cancelBtn:hover {
                        background-color: #89aeac;
                        color: #453f3f;
                        box-shadow: 1px 1px 10px gray;

                    }

                    .deleteBtn {
                        background-color: #cc0707;
                        color: white;
                        @extend %commonBtnDesign;
                        transition: all 0.5s;
                    }

                    .deleteBtn:hover {
                        background-color: #ff1414;
                        color: #ffffff;
                        box-shadow: 1px 1px 10px gray;
                    }
                }
            }

            @keyframes popupBasicDetails {
                0% {
                    opacity: 0;
                    transform: translateY(-40px);
                }

                100% {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        .AttendanceGradeGraph {
            border: 2px solid rgb(221, 221, 221);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            min-height: 200px;

            .GraphOptions {
                display: grid;
                grid-template-rows: auto auto;

                .options {
                    display: flex;
                    border-bottom: 1px solid rgb(221, 221, 221);

                    .option {
                        padding: 10px;
                        background-color: rgb(218, 218, 218);
                        cursor: pointer;
                        color: #919191;
                    }
                }

                .TimeFrame {
                    display: flex;
                    justify-self: flex-end;
                    margin: 8px 20px 8px 0px;
                    column-gap: 10px;

                    %commonTimeFrameDesign {
                        padding: 6px 7px 8px 7px;
                        border-radius: 10px;
                        font-size: 12px;
                        border: 1px solid rgb(111, 111, 111);
                        cursor: pointer;
                        transition: all 0.5s;
                    }

                    %commonTimeFrameDesign:hover {
                        background-color: rgb(111, 111, 111);
                        color: white;
                    }

                    %commonDropDownStyling {
                        display: none;
                        z-index: 1;
                        position: absolute;
                        background-color: rgb(255, 255, 255);
                        top: 28px;
                        left: 0;
                        max-height: 90px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        border-radius: 10px;
                        box-shadow: 1px 1px 10px black;

                        /* Custom scrollbar styles */
                        &::-webkit-scrollbar {
                            width: 8px;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: gray;
                            border-radius: 10px;
                        }
                    }

                    %commonDropdownOptionStyling {
                        padding: 7px 0px 7px 0px;
                        border-bottom: 1px solid rgb(158, 158, 158);
                        cursor: pointer;
                        font-size: 12px;
                        text-align: center;
                        transition: all 0.5s;
                        color: rgb(102, 102, 102);
                    }

                    .monthSec {
                        @extend %commonTimeFrameDesign;
                        position: relative;
                        text-align: center;

                        .months {
                            @extend %commonDropDownStyling;

                            .month {
                                @extend %commonDropdownOptionStyling;
                                width: 70px;
                            }

                            .month:hover {
                                background-color: gray;
                                color: white;
                            }


                        }
                    }

                    .monthSec:hover .months {
                        display: flex;
                        flex-direction: column;
                    }

                    .yearSec {
                        @extend %commonTimeFrameDesign;
                        position: relative;

                        .years {
                            @extend %commonDropDownStyling;

                            .year {
                                @extend %commonDropdownOptionStyling;

                                width: 45px;
                            }

                            .year:hover {
                                background-color: gray;
                                color: white;
                            }
                        }
                    }

                    .yearSec:hover .years {
                        display: flex;
                        flex-direction: column;
                    }

                }

                .courseSelector {
                    display: flex;
                    column-gap: 10px;
                    padding: 4px;
                    max-width: 500px;
                    overflow-x: auto;
                    margin-bottom: 5px;

                    &::-webkit-scrollbar {
                        height: 10px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #888888;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background-color: #555555;
                    }

                    table {
                        border-collapse: collapse;
                    }

                    .course {
                        padding: 8px;
                        border-radius: 10px;
                        font-size: 12px;
                        border: 1px solid rgb(111, 111, 111);
                        cursor: pointer;
                        transition: all 0.6s;
                        display: flex;
                        align-items: center;
                        column-gap: 5px;

                        .dot {
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                        }
                    }

                    .course:hover {
                        background-color: rgb(192, 192, 192);

                    }
                }
            }

            .custom-tooltip {
                background-color: white;
                box-shadow: 1px 1px 10px gray;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                row-gap: 8px;

                .label {
                    font-size: 12px;
                    background-color: #006676;
                    color: white;
                    padding: 8px;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                }

                .data {
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;
                    padding: 0px 8px 10px 8px;
                    text-align: center;
                }
            }
        }
    }

}