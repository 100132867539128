.SysPreferences {
    font-family: "Inter";

    h1 {
        font-size: larger;
    }

    .cancelSave {
        display: flex;
        column-gap: 16px;

        %commonShape {
            padding: 10px 20px 10px 20px;
            border-radius: 10px;
            outline: none;
            cursor: pointer;
            border: 1px solid gray;
            font-weight: 600;

            transition: all 0.3s;
        }

        justify-content: flex-end;

        .cancel {
            @extend %commonShape;
            background-color: white;

            &:hover {
                background-color: rgb(184, 184, 184);
            }
        }

        .saveChagnes {
            @extend %commonShape;
            background-color: #0099ff;
            color: white;

            &:hover {
                background-color: #0067ac;
            }
        }
    }
}