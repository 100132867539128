.user-mgt-container {
  width: 100%;
  font-family: "Inter";
  max-height: 80vh;
  overflow-y: auto;
}
.user-mgt-container .title {
  border-bottom: 1px solid #b8b8b8;
  padding: 0px 0px 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.user-mgt-container .title h2 {
  margin: 0;
}
.user-mgt-container .title span {
  color: #868686;
}
.user-mgt-container .add-member-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
  padding: 15px 25px;
  background-color: #F2F2F2;
  border-radius: 5px;
}
.user-mgt-container .add-member-container .add-member-title h4 {
  margin: 0;
}
.user-mgt-container .add-member-container .add-member-title p {
  font-size: 14px;
  color: #868686;
}
.user-mgt-container .add-member-btn-container {
  padding-right: 15px;
}
.user-mgt-container .add-member-btn-container .add-member-btn {
  padding: 14px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #2C7F75;
  color: #ffffff;
  border: none;
}
.user-mgt-container .add-member-btn-container .invite-member-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 0;
  border-radius: 10px;
}
.user-mgt-container .mgt-navbar-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #868686;
  border-radius: 5px;
}
.user-mgt-container .mgt-navbar-container a {
  text-decoration: none;
  color: #868686;
  margin-left: 35%;
}
.user-mgt-container .mgt-navbar-container .acc-header-container {
  width: 50%;
  height: 35px;
  align-content: center;
  border-radius: 5px;
  border-right: 1px solid #868686;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
  border-bottom-left-radius: 0%;
  cursor: pointer;
}
.user-mgt-container .mgt-navbar-container .user-header-container {
  width: 50%;
  height: 35px;
  align-content: center;
  border-radius: 5px;
  border-left: 1px solid #868686;
  border-top-left-radius: 0%;
  border-bottom-right-radius: 0%;
  border-bottom-left-radius: 0%;
  cursor: pointer;
}
.user-mgt-container .mgt-navbar-container .active.acc-header-container, .user-mgt-container .mgt-navbar-container .active.user-header-container {
  background-color: #F2F2F2;
}
.user-mgt-container .mgt-navbar-container .active.acc-header-container a, .user-mgt-container .mgt-navbar-container .active.user-header-container a {
  color: #000000;
}
.user-mgt-container::-webkit-scrollbar {
  width: 8px;
}
.user-mgt-container::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 10px;
}
.user-mgt-container::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}
.user-mgt-container::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}/*# sourceMappingURL=UserManagement.css.map */