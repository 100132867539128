@import url("https://fonts.googleapis.com/css?family=Inter");
.AdminAttendanceFlowChart {
  border-radius: 10px;
  padding: 10px;
  box-shadow: 1px 1px 10px rgb(111, 111, 111);
  font-family: "Inter";
}
.AdminAttendanceFlowChart .upperPart {
  height: 40px;
  padding: 0px 0px 0px 10px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}
.AdminAttendanceFlowChart .upperPart .title {
  font-weight: bold;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame {
  display: flex;
  justify-self: flex-end;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec, .AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec {
  padding: 10px 15px 8px 15px;
  border-radius: 10px;
  border: 1px solid rgb(111, 111, 111);
  cursor: pointer;
  transition: all 0.5s;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec:hover, .AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec:hover {
  background-color: rgb(111, 111, 111);
  color: white;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec .years, .AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec .months {
  display: none;
  z-index: 1;
  position: absolute;
  background-color: rgb(255, 255, 255);
  top: 38px;
  left: 0;
  max-height: 90px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  box-shadow: 1px 1px 10px black;
  /* Custom scrollbar styles */
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec .years::-webkit-scrollbar, .AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec .months::-webkit-scrollbar {
  width: 8px;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec .years::-webkit-scrollbar-track, .AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec .months::-webkit-scrollbar-track {
  background-color: transparent;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec .years::-webkit-scrollbar-thumb, .AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec .months::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec .years .year, .AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec .months .month {
  padding: 7px 0px 7px 0px;
  border-bottom: 1px solid rgb(158, 158, 158);
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  transition: all 0.5s;
  color: rgb(102, 102, 102);
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec {
  position: relative;
  text-align: center;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec .months .month {
  width: 100px;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec .months .month:hover {
  background-color: gray;
  color: white;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .monthSec:hover .months {
  display: flex;
  flex-direction: column;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec {
  position: relative;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec .years .year {
  width: 60px;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec .years .year:hover {
  background-color: gray;
  color: white;
}
.AdminAttendanceFlowChart .upperPart .TimeFrame .yearSec:hover .years {
  display: flex;
  flex-direction: column;
}
.AdminAttendanceFlowChart .chart {
  font-size: 11px;
}/*# sourceMappingURL=AdminAttendanceFlowChart.css.map */