.Dashboard {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 94%;
}
.Dashboard .dashboard-container {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  height: inherit;
}
.Dashboard .dashboard-container .leftSideDashboard {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.Dashboard .dashboard-container .rigthSideDashboard {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: inherit;
}/*# sourceMappingURL=Dashboard.css.map */