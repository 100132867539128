.FontSize {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  align-items: center;
  border-bottom: none;
}
.FontSize .title h3 {
  font-size: medium;
  margin: 20px 0px 0px 0px;
}
.FontSize .title span {
  color: gray;
}
.FontSize .testView {
  padding: 20px 0px;
}
.FontSize .sizes {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.FontSize .sizes .sizeOption {
  width: 100px;
  padding: 10px 0px 10px 0px;
  border: 2px solid #6d6d6d;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.FontSize .sizes .sizeOption:hover {
  background-color: #037ed1;
  color: white;
}/*# sourceMappingURL=FontSize.css.map */