.PopUpAdd {
  position: absolute;
  box-shadow: 0px 0px 10px #353434;
  width: 550px;
  background-color: white;
  z-index: 1;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  left: 30%;
  top: 0%;
}
.PopUpAdd .closeIcon {
  width: 30px;
  align-self: flex-end;
  cursor: pointer;
}
.PopUpAdd .fields {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
}
.PopUpAdd .fields label {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.PopUpAdd .fields label span {
  font-family: "Inter";
}
.PopUpAdd .fields label input[type=date], .PopUpAdd .fields label input[type=number], .PopUpAdd .fields label input[type=text] {
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
  height: 35px;
  outline: none;
  border: 1px solid gray;
}
.PopUpAdd .fields label input[type=number]::-webkit-inner-spin-button,
.PopUpAdd .fields label input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.PopUpAdd .fields label input[type=date] {
  width: 160px;
}
.PopUpAdd .fields label .knob {
  height: 30px;
  width: 50px;
  border-radius: 20px;
  background-color: red;
  position: relative;
}
.PopUpAdd .fields label .knob .btn {
  position: absolute;
  top: 1px;
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.7s;
}
.PopUpAdd .fields .FileInput {
  display: flex;
  flex-direction: row;
  width: -moz-fit-content;
  width: fit-content;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
}
.PopUpAdd .fields .FileInput img {
  max-width: 60px;
}
.PopUpAdd .fields .FileInput .delImg, .PopUpAdd .fields .FileInput .addImg {
  padding: 8px 20px 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}
.PopUpAdd .fields .FileInput .addImg {
  background-color: #2C7F75;
  color: white;
}
.PopUpAdd .fields .FileInput .delImg {
  color: rgb(77, 77, 77);
  background-color: transparent;
  border: 2px solid rgb(190, 190, 190);
}
.PopUpAdd .fields .SubField {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.PopUpAdd .fields .SubField .SubFields {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.PopUpAdd .saveDiscard {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  -moz-column-gap: 10px;
       column-gap: 10px;
  padding-top: 20px;
}
.PopUpAdd .saveDiscard .discard, .PopUpAdd .saveDiscard .save {
  padding: 10px 30px 10px 30px;
  border-radius: 10px;
  cursor: pointer;
}
.PopUpAdd .saveDiscard .save {
  background-color: #2C7F75;
  color: white;
  border: 1px solid transparent;
  transition: all 0.7s;
}
.PopUpAdd .saveDiscard .save:hover {
  background-color: #1a524c;
}
.PopUpAdd .saveDiscard .discard {
  background-color: transparent;
  border: 1px solid gray;
  transition: all 0.7s;
}
.PopUpAdd .saveDiscard .discard:hover {
  background-color: rgb(222, 222, 222);
  border: 1px solid rgb(222, 222, 222);
}/*# sourceMappingURL=PopUpAdd.css.map */