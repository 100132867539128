.PopUpExport {
  font-family: "Inter";
  position: absolute;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgb(71, 71, 71);
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  top: 20%;
  left: 30%;
  z-index: 1;
  width: 350px;
  animation: popupBasicDetails 0.7s forwards;
}
.PopUpExport .upperSec {
  display: flex;
  align-items: center;
}
.PopUpExport .upperSec h4 {
  flex-grow: 1;
  margin: 10px;
}
.PopUpExport .upperSec .closeBtn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.PopUpExport .upperSec .closeBtn img {
  cursor: pointer;
  align-self: flex-end;
}
.PopUpExport .studentSelected {
  overflow-y: auto;
  max-height: 300px;
}
.PopUpExport .studentSelected::-webkit-scrollbar {
  width: 8px;
}
.PopUpExport .studentSelected::-webkit-scrollbar-track {
  background-color: transparent;
  margin-top: 30px;
}
.PopUpExport .studentSelected::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}
.PopUpExport .studentSelected::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}
.PopUpExport .studentSelected thead {
  position: fixed;
  top: 48px;
  background-color: white;
}
.PopUpExport .studentSelected thead tr {
  display: flex;
}
.PopUpExport .studentSelected thead tr th {
  text-align: end;
  padding: 1px 3px 8px 25px;
}
.PopUpExport .studentSelected tbody {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 40px 0px 0px 0px;
}
.PopUpExport .studentSelected .studentDetails {
  display: flex;
  align-items: center;
  border: 1px solid rgb(162, 162, 162);
  border-radius: 10px;
}
.PopUpExport .studentSelected .studentDetails td {
  padding: 10px;
  flex-grow: 1;
}
.PopUpExport .studentSelected .studentDetails td img {
  border: 2px solid gray;
  width: 60px;
  border-radius: 50%;
}
.PopUpExport .methods {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-self: center;
  padding: 10px 0px;
}
.PopUpExport .methods .Pdf {
  cursor: pointer;
  padding: 10px 30px 10px 30px;
  font-size: 15px;
  color: white;
  background-color: #af0000;
  transition: all 0.8s;
  cursor: pointer;
  border: none;
  border-radius: 10px;
}
.PopUpExport .methods .Pdf:hover {
  background-color: #7c0000;
}
.PopUpExport .methods .Csv {
  cursor: pointer;
  padding: 10px 30px 10px 30px;
  font-size: 15px;
  color: white;
  background-color: #0f9089;
  transition: all 0.8s;
  cursor: pointer;
  border: none;
  border-radius: 10px;
}
.PopUpExport .methods .Csv:hover {
  background-color: #0a625d;
}
.PopUpExport .methods .Xls {
  cursor: pointer;
  padding: 10px 30px 10px 30px;
  font-size: 15px;
  color: white;
  background-color: #0b9142;
  transition: all 0.8s;
  cursor: pointer;
  border: none;
  border-radius: 10px;
}
.PopUpExport .methods .Xls:hover {
  background-color: #07622c;
}

@keyframes popupBasicDetails {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}/*# sourceMappingURL=PopUpExport.css.map */