.Teachers {
    display: flex;
    flex-direction: column;
    height: 95vh;
    width: fit-content;
    .gridInfoCard {
        display: flex;
        flex-direction: row;
        height: 70%;
        gap: 15px;

        .grid {
            display: flex;
            flex-direction: column;
            width: fit-content;
            row-gap: 16px;
        }
    }


}