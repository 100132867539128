.general-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Inter";
}
.general-container .title {
  border-bottom: 1px solid #b8b8b8;
  padding: 0px 0px 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.general-container .title h2 {
  margin: 0;
}
.general-container .title span {
  color: #868686;
}
.general-container form .input-wrap {
  position: relative;
  height: 37px;
  margin-bottom: 2rem;
}
.general-container form .input-wrap .input-field {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #bbb;
  padding: 0;
  font-size: 0.95rem;
  color: #151111;
}
.general-container form .input-wrap .input-label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.95rem;
  color: #bbb;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}
.general-container form .input-wrap .label-animate {
  top: -5px;
  font-size: 0.8rem;
  color: #2C7F75;
}
.general-container form .school-details {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.general-container form .school-details .logo-update {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.general-container form .school-details .logo-update img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f2f2f2;
}
.general-container form .school-details .logo-update button {
  height: 35px;
  width: 100px;
  border-radius: 8px;
  cursor: pointer;
}
.general-container form .school-details .logo-update .upload-btn {
  background-color: #2C7F75;
  color: #ffffff;
  border: none;
}
.general-container form .school-details .logo-update .delete-btn {
  background-color: transparent;
  color: #2C7F75;
  border: 1px solid #2C7F75;
}
.general-container form .school-details .contact-form-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding-bottom: 15px;
}
.general-container form .address-title {
  border-top: 1px solid #b8b8b8;
}
.general-container form .address-form-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.general-container form .form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
.general-container form .form-actions .submit-btn, .general-container form .form-actions .cancel-btn {
  height: 35px;
  width: 100px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  color: #fff;
}
.general-container form .form-actions .submit-btn {
  background-color: #2c7f75;
}
.general-container form .form-actions .cancel-btn {
  background-color: #ccc;
}/*# sourceMappingURL=General.css.map */