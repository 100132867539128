$bgColor: #ededec;

.CreateEvent-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .CreateEvent-bg {
        background: $bgColor;
        border-radius: 8px;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);


        .CreateEvent-Box {
            background: white;
            border-radius: 8px;
            width: 80rem;
            height: 50rem;
            padding: 10px 20px;
            margin: 20px;
            overflow-x: hidden;
            overflow-y: scroll;

            /* remember to add Style the file input button */
            input[type="file"] {
                border: none;
                padding: 0;
                margin: 10px 0;
            }

            .CreateEvent-FileInputLabel {
                display: block;
                padding: 10px 20px;
                color: #333;
                border: 1px solid #ccc;
                border-radius: 4px;
                cursor: pointer;
                text-align: center;
            }

            .CreateEvent-FileInputLabel:hover {
                background-color: #e5e5e5;
            }

            .CreateEvent-CloseBtn {
                background: none;
                border: none;
                font-size: 1.5rem;
                cursor: pointer;
                float: right;
                padding: 10px;
            }

            .CreateEvent-Form {
                padding: 20px;

                h2 {
                    margin-top: 0;
                    color: #333;
                }

                input,
                select,
                textarea {
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 15px;
                    border: none;
                    border-bottom: 1px solid #ccc;
                    box-sizing: border-box;
                }

                input:focus {
                    outline: none;
                }

                button {
                    background-color: #0078d5;
                    color: white;
                    padding: 10px 20px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 1rem;
                    box-sizing: border-box;
                }

                button:hover {
                    background-color: #0056b3;
                }

                .btn-discard {
                    border: 1px solid #e2e2e2;
                    background-color: white;
                    color: #0078d5;
                }

                .btn-discard:hover {
                    background-color: rgb(194, 190, 190);
                }

                label {
                    display: block;
                    margin-bottom: 5px;
                }
            }

            .Text-Events {
                // position: absolute;
                // left: 30%;
                // top: 20%;
                // width: 850px;
                // height: 500px;
                box-shadow: 1px 1px 10px gray;
                border-radius: 10px;
                // background-color: $bgColor ;
                animation: popUpAddEvent 0.7s forwards;
                padding: 10px;
                

                .EventDescriptAndTextEdit {
                    padding: 10px;
                    background-color: white;
                    border-radius: 10px;
                    // width: fit-content;
                    
                    
                    .TextEditor {
                        .ql-snow {
                            border: none;
                        }
                    }

                    .SaveDiscard {
                        display: flex;
                        column-gap: 10px;
                        padding: 10px;

                        %commonBtnLayout {
                            border: 1px #ededec solid;
                            padding: 10px 16px 10px 16px;
                            border-radius: 10px;
                            font-weight: bold;
                        }

                        .save {
                            color: white;
                            background-color: #0078d4;
                            cursor: pointer;
                            @extend %commonBtnLayout;
                        }

                        .discard {
                            color: #0078d4;
                            background-color: white;
                            cursor: pointer;
                            @extend %commonBtnLayout;
                        }
                    }
                }
            }

        }
    }


}