.InfoCard {
  box-shadow: 0px 0px 10px rgb(172, 172, 172);
  border-radius: 10px;
  padding: 10px 25px 16px 25px;
  max-width: 320px;
  font-family: "Inter";
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}
.InfoCard .UpperPart {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.InfoCard .UpperPart .legend {
  display: flex;
  justify-content: space-between;
}
.InfoCard .UpperPart .legend h3 {
  margin: 10px 0px 10px 0px;
}
.InfoCard .UpperPart .legend img {
  width: 25px;
  cursor: pointer;
}
.InfoCard .UpperPart .profile {
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 16px;
       column-gap: 16px;
  color: #0254ec;
}
.InfoCard .UpperPart .profile .InfoCard-Name-disciplines {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.InfoCard .UpperPart .profile .InfoCard-Name-disciplines .Name {
  margin: 0px;
}
.InfoCard .UpperPart .profile .InfoCard-Name-disciplines .disciplines {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.InfoCard .UpperPart .profile .InfoCard-Name-disciplines .disciplines .course {
  font-size: 13px;
}
.InfoCard .UpperPart .profile .profileCirle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px gray;
  border: 5px solid white;
}
.InfoCard .UpperPart .profile .profileCirle img {
  width: 100px;
  border-radius: 50%;
}
.InfoCard .UpperPart .profile .contacts {
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.InfoCard .UpperPart .profile .contacts .contactIcons {
  background-color: rgb(223, 223, 223);
  padding: 5px 5px 0px 5px;
  border: 5px solid rgb(223, 223, 223);
  border-radius: 50%;
  cursor: pointer;
}
.InfoCard .UpperPart .profile .contacts .contactIcons img {
  width: 20px;
}
.InfoCard .about {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.InfoCard .about .aboutText {
  margin: 0px;
  font-size: 14px;
}
.InfoCard .about h3 {
  margin: 0px;
}
.InfoCard .additionalInfo {
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 20px;
  width: 100%;
  -moz-column-gap: 10%;
       column-gap: 10%;
}
.InfoCard .additionalInfo .elm {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.InfoCard .additionalInfo .elm .title {
  font-weight: bold;
}
.InfoCard .additionalInfo .elm .value {
  font-size: 14px;
}/*# sourceMappingURL=InfoCard.css.map */