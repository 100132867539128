.sideBarView {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  align-items: center;
  justify-content: flex-start;
  -moz-column-gap: 130px;
       column-gap: 130px;
}
.sideBarView .title h3 {
  font-size: medium;
  margin: 20px 0px 0px 0px;
}
.sideBarView .title span {
  color: gray;
}
.sideBarView .knob {
  margin: 20px 0px 0px 0px;
  width: 75px;
  height: 37px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.8);
  border-radius: 30px;
  position: relative;
  transition: all 0.7s;
}
.sideBarView .knob .circle {
  width: 30px;
  position: absolute;
  height: 30px;
  border-radius: 50%;
  top: 1.8px;
  left: 2px;
  box-shadow: 0px 0px 2px rgb(54, 54, 54);
  background-color: rgb(238, 235, 235);
  border: 2px solid white;
  transition: all 0.5s;
  z-index: 1;
  cursor: pointer;
}
.sideBarView .knob .knobState {
  position: absolute;
  top: 9px;
  color: white;
  transition: all 0.7s;
}/*# sourceMappingURL=SideBarView.css.map */