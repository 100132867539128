.FontSize {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 20px 20px;
    border-bottom: 1px solid rgb(128, 128, 128, 0.5);
    align-items: center;

    .title {
        h3 {
            font-size: medium;
            margin: 20px 0px 0px 0px;
        }

        span {
            color: gray;
        }
    }

    border-bottom: none;

    .testView {
        padding: 20px 0px;
    }

    .sizes {
        display: flex;
        column-gap: 20px;

        .sizeOption {
            width: 100px;
            padding: 10px 0px 10px 0px;
            border: 2px solid #6d6d6d;
            text-align: center;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                background-color: #037ed1;
                color: white;
            }
        }

    }

}