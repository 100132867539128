.AdminStudentAddExport {
    display: flex;
    column-gap: 10px;
    background-color: white;
    box-shadow: 1px 1px 10px gray;
    padding: 10px;
    border-radius: 10px;

    %commonBtnLayout {
        padding: 8px 16px 10px 16px;
        border-radius: 10px;
        color: white;
        cursor: pointer;
        font-weight: 600;
        transition: all 0.7s;
    }

    .Add-btn {
        background-color: #00ba88;
        @extend %commonBtnLayout;
    }
    .Add-btn:hover{
        background-color: #09e0a7;
    }

    .ExportAll-btn {
        background-color: #068396;
        @extend %commonBtnLayout;
    }
    .ExportAll-btn:hover{
        background-color: #00a8c2;
    }

}