.EventList {
  position: relative;
  overflow-y: auto;
}
.EventList::-webkit-scrollbar {
  width: 8px;
}
.EventList::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 8px 0px 0px 0px;
}
.EventList::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}
.EventList::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}
.EventList .EventItem-Container {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.EventList .EventItem-Container .EventItem-Date {
  font-weight: normal;
  color: #2b2b2b;
  font-size: 0.8rem;
}
.EventList .EventItem-Container .EventItems {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: flex-start;
  justify-items: center;
  margin-top: 10px;
}
.EventList .EventItem-Container .EventItems .EventItem-Divider {
  content: "";
  position: relative;
  width: 0px;
  height: 45px;
  border-left: 5px solid red;
  border-radius: 50px;
}
.EventList .EventItem-Container .EventItems .EventItem-Time {
  display: flex;
  flex-direction: column;
  color: #666;
}
.EventList .EventItem-Container .EventItems .EventItem-Time .EventItem-Duration {
  font-weight: normal;
}
.EventList .EventItem-Container .EventItems .EventItem-Details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.EventList .EventItem-Container .EventItems .EventItem-Details .EventItem-Title {
  font-size: 1rem;
  color: #000000;
  font-weight: bold;
}
.EventList .EventItem-Container .EventItems .EventItem-Details .EventItem-Link {
  color: #666;
  text-decoration: none;
  font-size: 0.8rem;
}/*# sourceMappingURL=EventList.css.map */