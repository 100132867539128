.Billing {
    width: 100%;
    font-family: "Inter";

    .title {
        border-bottom: 1px solid #b8b8b8;
        padding: 0px 0px 10px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        h2 {
            margin: 0;
        }

        span {
            color: #868686;
        }
    }

    .options {
        .historyCancel {
            display: flex;
            justify-content: space-between;
        }

        .payementMethRecurence {
            display: flex;
            justify-content: space-between;
        }

    }

}