@import url('https://fonts.googleapis.com/css?family=Inter');

.AdminAttendanceFlowChart {
    border-radius: 10px;
    
    padding: 10px;
    box-shadow: 1px 1px 10px rgb(111, 111, 111);
    font-family: 'Inter';

    .upperPart {
        height: 40px;
        padding: 0px 0px 0px 10px;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;

        .title {
            font-weight: bold;
        }

        .TimeFrame {
            display: flex;
            justify-self: flex-end;
            column-gap: 10px;

            %commonTimeFrameDesign {
                padding: 10px 15px 8px 15px;
                border-radius: 10px;
                border: 1px solid rgb(111, 111, 111);
                cursor: pointer;
                transition: all 0.5s;
            }

            %commonTimeFrameDesign:hover {
                background-color: rgb(111, 111, 111);
                color: white;
            }

            %commonDropDownStyling {
                display: none;
                z-index: 1;
                position: absolute;
                background-color: rgb(255, 255, 255);
                top: 38px;
                left: 0;
                max-height: 90px;
                overflow-y: auto;
                overflow-x: hidden;
                border-radius: 10px;
                box-shadow: 1px 1px 10px black;

                /* Custom scrollbar styles */
                &::-webkit-scrollbar {
                    width: 8px;
                }

                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: gray;
                    border-radius: 10px;
                }
            }

            %commonDropdownOptionStyling {
                padding: 7px 0px 7px 0px;
                border-bottom: 1px solid rgb(158, 158, 158);
                cursor: pointer;
                font-size: 12px;
                text-align: center;
                transition: all 0.5s;
                color: rgb(102, 102, 102);
            }

            .monthSec {
                @extend %commonTimeFrameDesign;
                position: relative;
                text-align: center;

                .months {
                    @extend %commonDropDownStyling;

                    .month {
                        @extend %commonDropdownOptionStyling;
                        width: 100px;
                    }

                    .month:hover {
                        background-color: gray;
                        color: white;
                    }


                }
            }

            .monthSec:hover .months {
                display: flex;
                flex-direction: column;
            }

            .yearSec {
                @extend %commonTimeFrameDesign;
                position: relative;

                .years {
                    @extend %commonDropDownStyling;

                    .year {
                        @extend %commonDropdownOptionStyling;
                        
                        width: 60px;
                    }

                    .year:hover {
                        background-color: gray;
                        color: white;
                    }
                }
            }

            .yearSec:hover .years {
                display: flex;
                flex-direction: column;
            }

        }
    }

    .chart {
        font-size: 11px;
    }
}