.popUpDelete {
    box-shadow: 1px 1px 100px rgb(101, 101, 101);
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    z-index: 1;
    top: 10%;
    left: 25%;
    align-items: center;
    width: 320px;
    text-align: center;
    border-radius: 10px;
    padding: 10px 20px 20px 20px;
    animation: popupBasicDetails 0.7s forwards;

    .closeBtn {
        align-self: flex-end !important;
        cursor: pointer;
    }

    .dangerSign {
        width: 50%;
    }

    .cancel-delete-container {
        display: flex;
        column-gap: 20px;

        %commonBtnDesign {
            padding: 10px 20px 10px 20px;
            border-radius: 6px;
            cursor: pointer;
        }

        .cancelBtn {
            background-color: #557573;
            color: white;
            @extend %commonBtnDesign;
            transition: all 0.5s;
        }

        .cancelBtn:hover {
            background-color: #89aeac;
            color: #453f3f;
            box-shadow: 1px 1px 10px gray;

        }

        .deleteBtn {
            background-color: #cc0707;
            color: white;
            @extend %commonBtnDesign;
            transition: all 0.5s;
        }

        .deleteBtn:hover {
            background-color: #ff1414;
            color: #ffffff;
            box-shadow: 1px 1px 10px gray;
        }
    }
}

@keyframes popupBasicDetails {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}