.users-list {
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 50vh;
  overflow: scroll;
}
.users-list .users-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
}
.users-list .users-list-header h4 {
  display: flex;
  gap: 8px;
}
.users-list .users-list-header h4 span {
  color: #868686;
  font-weight: 400;
}
.users-list .users-list-header .users-search-bar-wrapper .users-search-bar {
  background-image: url("../../../../icons/searchIcon.svg");
  background-position: 5px;
  background-size: 2vh;
  padding: 10px 10px 10px 35px;
  background-repeat: no-repeat;
  outline: none;
  border: 1px solid #6d6d6d;
  border-radius: 10px;
  flex-grow: 1;
}
.users-list table {
  width: 100%;
  border-collapse: collapse;
}
.users-list table th,
.users-list table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.users-list table th p,
.users-list table td p {
  margin: 0;
}
.users-list table thead {
  background-color: #f4f4f4;
}
.users-list table thead th {
  font-weight: bold;
}
.users-list table tbody {
  overflow: scroll;
}
.users-list table tbody tr:hover {
  background-color: #f9f9f9;
}
.users-list table tbody .user-profile {
  display: flex;
  align-items: center;
}
.users-list table tbody .user-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.users-list table tbody .user-profile p {
  margin: 0;
}
.users-list table tbody td {
  position: relative;
}
.users-list table tbody td .user-options {
  width: 20px;
  cursor: pointer;
}
.users-list table tbody td .clicked-user-options {
  background-color: #F2F2F2;
  border-radius: 50%;
  width: 20px;
  padding: 15%;
  cursor: pointer;
}
.users-list table tbody td .dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 150px;
  z-index: 100;
  left: -150%;
  top: 80%;
}
.users-list table tbody td .dropdown-menu .menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.users-list table tbody td .dropdown-menu .menu-item:hover {
  background-color: #f5f5f5;
}
.users-list table tbody td .dropdown-menu .menu-item img {
  width: 20px;
}
.users-list table tbody td .dropdown-menu .menu-item p {
  margin: 0;
  font-size: 14px;
  color: #333;
}
.users-list table tbody td .dropdown-menu .change-roles-menu p {
  color: #5375E2;
}
.users-list table tbody td .dropdown-menu .delete-user-menu p {
  color: #ff0000;
}/*# sourceMappingURL=UsersList.css.map */