.nav-bar {
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgb(172, 172, 172);

    .header-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;

        h3 {
            flex-grow: 0.5;
            padding: 0px 0px 0px 20px;
        }

        .user-info-sec {

            flex-grow: 1;

            display: flex;
            justify-content: flex-end;

            .user-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                margin: 0px 10px 0px 0px;
                width: fit-content;

                img {
                    border-radius: 50%;
                    width: 6vh;
                    height: 6vh;
                    cursor: pointer;
                }
            }
        }
    }


}