.AdminStudentAddExport {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px gray;
  padding: 10px;
  border-radius: 10px;
}
.AdminStudentAddExport .ExportAll-btn, .AdminStudentAddExport .Add-btn {
  padding: 8px 16px 10px 16px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.7s;
}
.AdminStudentAddExport .Add-btn {
  background-color: #00ba88;
}
.AdminStudentAddExport .Add-btn:hover {
  background-color: #09e0a7;
}
.AdminStudentAddExport .ExportAll-btn {
  background-color: #068396;
}
.AdminStudentAddExport .ExportAll-btn:hover {
  background-color: #00a8c2;
}/*# sourceMappingURL=AdminStudentAddExport.css.map */