.account-manager-container {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}
.account-manager-container .account-manager-subtitles {
  padding-top: 15px;
  font-size: 14px;
}
.account-manager-container .account-manager-subtitles h4 {
  font-weight: 600;
  color: #868686;
}
.account-manager-container .account-manager-subtitles p {
  font-size: 12px;
}
.account-manager-container .accounts-container {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 20px;
       column-gap: 20px;
  padding-right: 10px;
}
.account-manager-container .accounts-container .accounts-title {
  flex-grow: 1;
}
.account-manager-container .accounts-container .accounts-title p {
  font-size: 14px;
}
.account-manager-container .accounts-container .accounts-email-container {
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  flex-grow: 2;
  width: 60%;
}
.account-manager-container .accounts-container .accounts-email-container .account-email {
  padding: 0 25px;
  border: 0.5px solid #F2F2F2;
}
.account-manager-container .accounts-container .accounts-email-container .account-email .email {
  font-size: 12px;
}
.account-manager-container .accounts-container .accounts-email-container .accounts-email-function {
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  gap: 8px;
  background-color: #F2F2F2;
  cursor: pointer;
}
.account-manager-container .accounts-container .accounts-email-container .accounts-email-function img {
  color: #054DCE;
  width: 24px;
}
.account-manager-container .accounts-container .accounts-email-container .accounts-email-function p {
  font-weight: 600;
  font-size: 12px;
  color: #054DCE;
}
.account-manager-container .account-password button {
  height: 35px;
  width: auto;
  padding: 0 15px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #2C7F75;
  color: #ffffff;
  border: none;
}
.account-manager-container .account-remove {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  gap: 15px;
}
.account-manager-container .account-remove button {
  height: 35px;
  width: auto;
  padding: 0 15px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #AF0101;
  color: #ffffff;
  border: none;
}
.account-manager-container .account-remove .account-del-btn button {
  background-color: #F2F2F2;
  color: #AF0101;
}/*# sourceMappingURL=AccountManager.css.map */