@import url('https://fonts.googleapis.com/css?family=Inter');

.widget-container {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .widget {
        display: flex;
        flex-direction: row;
        background-color: white;
        border-radius: 10px;
        padding: 10px 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        gap: 16px;
        justify-content: space-around;

        .widget-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 60px;
        }

        .widget-text {
            color: #616161;
            font-family: Inter;
            font-weight: bold;
            text-align: left;

            h2 {
                color: #000000;
                text-align: center;
                font-size: 18px;
            }

        }
    }


}