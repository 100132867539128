.calendar-group-container {
  padding: 0px 10px 10px 30px;
}
.calendar-group-container .calendar-types {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.calendar-group-container .calendar-types .calendar-type {
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
}
.calendar-group-container .calendar-types .calendar-type .custom-radio {
  width: 20px;
  height: 20px;
  border: 2px solid #aaa;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-group-container .calendar-types .calendar-type .custom-radio.selected {
  border-color: #007bff;
  background-color: #007bff;
}
.calendar-group-container .calendar-types .calendar-type .custom-radio.selected::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: 40%;
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
}/*# sourceMappingURL=CalendarGroup.css.map */