.nav-bar {
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgb(172, 172, 172);
}
.nav-bar .header-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.nav-bar .header-items h3 {
  flex-grow: 0.5;
  padding: 0px 0px 0px 20px;
}
.nav-bar .header-items .user-info-sec {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
.nav-bar .header-items .user-info-sec .user-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 0px 10px 0px 0px;
  width: -moz-fit-content;
  width: fit-content;
}
.nav-bar .header-items .user-info-sec .user-info img {
  border-radius: 50%;
  width: 6vh;
  height: 6vh;
  cursor: pointer;
}/*# sourceMappingURL=Header.css.map */