.TeacherNavBar {
  display: flex;
  padding: 20px 10px;
  justify-content: space-between;
  -moz-column-gap: 10px;
       column-gap: 10px;
  position: relative;
}
.TeacherNavBar .options {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  min-width: 350px;
  justify-content: space-between;
}
.TeacherNavBar .options .exportCsv, .TeacherNavBar .options .addTeacher {
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  box-shadow: 0px 0px 10px rgb(172, 172, 172);
  cursor: pointer;
}
.TeacherNavBar .options .addTeacher {
  background-color: #0254ec;
  color: white;
  font-size: 16px;
}
.TeacherNavBar .options .exportCsv {
  background-color: white;
}
.TeacherNavBar .searchEngTeacher {
  box-shadow: 0px 0px 10px rgb(172, 172, 172);
  border-radius: 10px;
  display: flex;
  padding: 0px 0px 0px 10px;
  -moz-column-gap: 6px;
       column-gap: 6px;
  flex-grow: 2;
}
.TeacherNavBar .searchEngTeacher img {
  width: 20px;
}
.TeacherNavBar .searchEngTeacher input {
  border-radius: 10px;
  padding: 10px 0px;
  border: none;
  font-size: 16px;
  outline: none;
  color: gray;
  width: 100%;
}/*# sourceMappingURL=TeacherNavBar.css.map */