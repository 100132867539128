.Admin {
    display: flex;
    column-gap: 12px;
    padding: 10px;
    width: 98.65%;
    height: 97.2vh;

    p,
    span,
    tr,
    div>span,
    button,
    a {
        font-size: var(--font-size-bodyText) !important;
    }

    h1,
    h2,
    h3 {
        font-size: var(--font-size-title-cat1) !important;
    }

    h4,
    h5 {
        font-size: var(--font-size-title-cat2) !important;
    }
}