@import url('https://fonts.googleapis.com/css?family=Inter');

.SideBar {
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 8px rgb(165, 165, 165);
    padding: 20px;
    border-radius: 10px;
    transition: width 1s ease;
    width: fit-content;

    .viewSideBar {
        display: flex;

        img {
            width: 40px;
            cursor: pointer;
            padding: 0px 0px 20px 0px;
        }
    }

    .sideBarLinks {

        display: flex;
        flex-direction: column;
        row-gap: 30px;
        height: 100%;

        .link {
            display: flex;
            align-items: center;
            column-gap: 15px;
            color: black;
            text-decoration: none;
            padding: 0px 30px 0px 0px;
        }

        @keyframes linkAppear {
            0% {
                transform: translateX(-50px);
                opacity: 0;
            }

            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        .logout {
            flex-grow: 2;
            align-items: flex-end;
            padding-bottom: 20px;
        }
    }

}