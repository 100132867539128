.role-change-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;

    .role-change-box {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 300px;

        h3 {
            margin-bottom: 20px;
        }

        .roles-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;

            .role-item {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                input {
                    margin-right: 10px;
                }
            }


        }

        .role-change-buttons {
            display: flex;
            justify-content: space-between;

            button {
                padding: 10px 20px;
                border-radius: 4px;
                cursor: pointer;
                font-size: 14px;
                border: none;
            }

            button:hover {
                opacity: 0.8;
            }

            .save-button {
                background-color: #28a745;
                color: white;
            }
        }
    }
}