@mixin btnCommonDesign($textColor, $backgroundColor) {
    padding: 10px 30px 10px 30px;
    font-size: 15px;
    color: $textColor;
    background-color: $backgroundColor;
    transition: all 0.8s;
    cursor: pointer;
    border: none;
    border-radius: 10px;

    &:hover {
        background-color: darken($color: $backgroundColor, $amount: 10);
    }
}

.PopUpExport {
    font-family: 'Inter';
    position: absolute;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgb(71, 71, 71);
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    top: 20%;
    left: 30%;
    z-index: 1;
    width: 350px;

    .upperSec {
        display: flex;
        align-items: center;

        h4 {
            flex-grow: 1;
            margin: 10px;
        }

        .closeBtn {
            display: flex;
            flex-direction: column;

            img {
                cursor: pointer;
                align-self: flex-end;
            }

            flex-grow: 1;
        }

    }

    .studentSelected {
        overflow-y: auto;
        max-height: 300px;
        &::-webkit-scrollbar {
            width: 8px;
          }
        
          &::-webkit-scrollbar-track {
            background-color: transparent;
            margin-top: 30px;
          }
        
          &::-webkit-scrollbar-thumb {
            background-color: #888888;
            border-radius: 10px;
          }
        
          &::-webkit-scrollbar-thumb:hover {
            background-color: #555555;
          }
        thead {
            position: fixed;
            top: 48px;
            background-color: white;
            tr {
                display: flex;

                th {
                    text-align: end;
                    padding: 1px 3px 8px 25px;
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            margin: 40px 0px 0px 0px;
        }

        .studentDetails {
            display: flex;
            align-items: center;
            border: 1px solid rgb(162, 162, 162);
            border-radius: 10px;

            td {
                padding: 10px;
                flex-grow: 1;

                img {
                    border: 2px solid gray;
                    width: 60px;
                    border-radius: 50%;
                }
            }
        }
    }



    .methods {
        display: flex;
        column-gap: 20px;
        align-self: center;
padding: 10px 0px;
        .Pdf {
            cursor: pointer;
            @include btnCommonDesign(white, #af0000);
        }

        .Csv {
            cursor: pointer;
            @include btnCommonDesign(white, #0f9089);
        }

        .Xls {
            cursor: pointer;
            @include btnCommonDesign(white, #0b9142);
        }
    }

    animation: popupBasicDetails 0.7s forwards;
}

@keyframes popupBasicDetails {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}