.invite-container {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  position: absolute;
  padding: 40px;
  justify-content: space-between;
  width: 120%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  position: relative;
}
.invite-container .invite-action {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
}
.invite-container .invite-action .invite-action-header h4 {
  margin: 0;
}
.invite-container .invite-action .invite-action-header p {
  font-size: 14px;
  color: #868686;
}
.invite-container .invite-action .invite-action-email form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.invite-container .invite-action .invite-action-email form .invite-action-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.invite-container .invite-action .invite-action-email form .invite-action-items select {
  width: 80%;
  height: 5vh;
}
.invite-container .invite-action .invite-action-email form .invite-action-items .email-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border: 1px solid #EDEFF3;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 80%;
}
.invite-container .invite-action .invite-action-email form .invite-action-items .email-tags .email-tag {
  display: flex;
  align-items: center;
  background-color: #EDEFF3;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  color: #333;
  position: relative;
}
.invite-container .invite-action .invite-action-email form .invite-action-items .email-tags .email-tag .remove-email {
  margin-left: 8px;
  cursor: pointer;
  color: #ff0000;
  font-weight: bold;
}
.invite-container .invite-action .invite-action-email form .invite-action-items .email-tags .invite-action-input {
  border: none;
  flex: 1;
  outline: none;
  padding: 5px;
  font-size: 14px;
  background-color: transparent;
}
.invite-container .invite-action .invite-action-email form .invite-action-items .email-tags input {
  padding: 5px;
  font-size: 14px;
}
.invite-container .invite-action .invite-action-email form .invite-action-items .email-tags input::-moz-placeholder {
  color: #aaa;
}
.invite-container .invite-action .invite-action-email form .invite-action-items .email-tags input::placeholder {
  color: #aaa;
}
.invite-container .invite-action .invite-action-email form .invite-button {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.invite-container .invite-action .invite-action-email form .invite-button button {
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.invite-container .invite-action .invite-action-email form .invite-button .invite-action-cancel {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0%;
  padding: 14px;
  background-color: transparent;
  color: #2C7F75;
  text-decoration: underline;
}
.invite-container .invite-action .invite-action-email form .invite-button .invite-action-submit {
  padding: 14px;
  background-color: #2C7F75;
  color: #ffffff;
}
.invite-container .invite-description {
  display: flex;
  flex-direction: column;
  background-color: #F6F8FA;
  padding: 40px;
  height: 60vh;
  border-radius: 10px;
}
.invite-container .invite-description h4 {
  font-weight: 400;
}
.invite-container .invite-description .invite-description-details {
  margin: 0;
}
.invite-container .invite-description .invite-description-details p {
  font-size: 14px;
  color: #6F7686;
}/*# sourceMappingURL=InviteMember.css.map */