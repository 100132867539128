@import url('https://fonts.googleapis.com/css?family=Inter');

.form-login-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  color: black;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 62vh;
  width: 45vh;
  padding: 30px;
  font-family: Inter;

  h2 {
    margin-top: 10vh;
    letter-spacing: 3px;
  }

  p {
    font-size: 15px;
    /* text-transform: lowercase; */
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        background-color: #eff0f0;
        display: flex;
        flex-direction: column;
        margin: 12px;
        width: 40vh;
        height: 7vh;
        border: 1px solid black;
        font-family: Inter;
        padding: 0px 0px 0px 20px;
        border-radius: 8px;
    }

    .button-login {
        margin-top: 12px;
        width: 25vh;
        height: 8vh;
        border: none;
        border-radius: 45px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        text-transform: uppercase;
        font-family: Inter;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 2.5px;
        background-color: #3490ED;
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
    }

    .button-login:hover {
        background-color: #FDBAC2;
        box-shadow: 0px 15px 20px rgba(253, 186, 194, 0.4);
        color: #87398F;
        transform: translateY(-4px);
    } 
  }

}

