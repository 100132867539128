.Events-Container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  width: 100%;
  height: 95vh;
  position: relative;
}
.Events-Container .Events-Calendar-Container {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 12px;
       column-gap: 12px;
  height: 90%;
}
.Events-Container .Events-Calendar-Container .Events-Content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}/*# sourceMappingURL=Event.css.map */