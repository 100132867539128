.Home {
  position: relative;
}
.Home .navigation {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: white;
}
.Home .navigation .logo {
  display: flex;
  align-items: center;
  justify-self: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.Home .navigation .navLinks {
  display: flex;
  align-items: center;
  justify-self: center;
  -moz-column-gap: 30px;
       column-gap: 30px;
}
.Home .navigation .navLinks .link {
  text-decoration: none;
  color: #68686c;
  transition: all 0.7s;
}
.Home .navigation .navLinks .link:hover {
  color: #5375e2;
}
.Home .navigation .navLinks .loginBtn {
  border-radius: 10px;
  border: 2px solid #5375e2;
  color: black;
  padding: 6px 16px 10px 16px;
  background-color: transparent;
  transition: all 0.7s;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
}
.Home .navigation .navLinks .loginBtn:hover {
  background-color: #5375e2;
  border-color: #5375e2;
  color: white;
  box-shadow: 1px 1px 10px #68686c;
}
.Home .navigation .navLinks .registerBtn {
  border-radius: 10px;
  border: 2px solid #5375e2;
  color: white;
  padding: 6px 16px 10px 16px;
  background-color: #5375e2;
  transition: all 0.7s;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
}
.Home .navigation .navLinks .registerBtn:hover {
  background-color: #2752db;
  border-color: #2752db;
  color: white;
  box-shadow: 1px 1px 10px #68686c;
}
.Home .header {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px auto 0px auto;
  padding: 50px 0px 50px 0px;
  -moz-column-gap: 250px;
       column-gap: 250px;
  height: 50vh;
}
.Home .header .leftSec {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.Home .header .leftSec .title-subtitle {
  width: 400px;
}
.Home .header .leftSec .title-subtitle .focusedWord {
  color: #5375e2;
}
.Home .header .leftSec .title-subtitle p {
  color: #68686c;
}
.Home .header .leftSec .getStartedBtn {
  border-radius: 10px;
  border: 2px solid #5375e2;
  color: white;
  padding: 6px 16px 10px 16px;
  background-color: #5375e2;
  transition: all 0.7s;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 16px;
}
.Home .header .leftSec .getStartedBtn:hover {
  background-color: #2752db;
  border-color: #2752db;
  color: white;
  box-shadow: 1px 1px 10px #68686c;
}
.Home .header .rightSec {
  position: relative;
}
.Home .header .rightSec .floatingCard {
  display: flex;
  -moz-column-gap: 6px;
       column-gap: 6px;
  position: absolute;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1;
  border: 2px solid #5375e2;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #68686c;
  padding: 2px 8px 2px 4px;
  background-color: white;
}
.Home .header .rightSec .floatingCard img {
  width: 30px;
}
.Home .header .rightSec .floatingCard .card-info {
  display: flex;
  flex-direction: column;
}
.Home .header .rightSec .floatingCard .card-info .card-title {
  font-weight: bold;
}
.Home .header .rightSec .floatingCard .card-info .sub-Text {
  font-size: 12px;
  color: #68686c;
}
.Home .header .rightSec .card1 {
  top: 40px;
  left: -50px;
}
.Home .header .rightSec .card2 {
  top: 0px;
  right: -50px;
  flex-direction: column;
  align-items: center;
}
.Home .header .rightSec .card2 img {
  width: 40px;
}
.Home .header .rightSec .card2 .card-info {
  align-items: center;
}
.Home .header .rightSec .card3 {
  bottom: 0px;
  right: -20px;
}
.Home .header .rightSec .nonfilled-Circle {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 2px solid #6054E3;
}
.Home .header .rightSec .filled-circle {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #6054E3;
}
.Home .HowItWorks {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 50px;
  background-color: #0f215a;
  text-align: center;
  padding: 20px 0px 40px 0px;
}
.Home .HowItWorks h2 {
  color: white;
}
.Home .HowItWorks .steps {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px auto 0px auto;
  align-items: center;
}
.Home .HowItWorks .steps .dashes {
  width: 200px;
  height: 0px;
  border: 0.5px dashed white;
}
.Home .HowItWorks .steps .step {
  color: white;
  width: 250px;
}
.Home .HowItWorks .steps .step img {
  width: 100px;
}/*# sourceMappingURL=Home.css.map */