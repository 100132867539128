.SysPreferences {
  font-family: "Inter";
}
.SysPreferences h1 {
  font-size: larger;
}
.SysPreferences .cancelSave {
  display: flex;
  -moz-column-gap: 16px;
       column-gap: 16px;
  justify-content: flex-end;
}
.SysPreferences .cancelSave .saveChagnes, .SysPreferences .cancelSave .cancel {
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  border: 1px solid gray;
  font-weight: 600;
  transition: all 0.3s;
}
.SysPreferences .cancelSave .cancel {
  background-color: white;
}
.SysPreferences .cancelSave .cancel:hover {
  background-color: rgb(184, 184, 184);
}
.SysPreferences .cancelSave .saveChagnes {
  background-color: #0099ff;
  color: white;
}
.SysPreferences .cancelSave .saveChagnes:hover {
  background-color: #0067ac;
}/*# sourceMappingURL=SysPreferences.css.map */