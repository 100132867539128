.InterfaceTheme {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 20px 20px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  align-items: center;
  justify-content: flex-start;
  -moz-column-gap: 60px;
       column-gap: 60px;
}
.InterfaceTheme .title h3 {
  font-size: medium;
  margin: 20px 0px 0px 0px;
}
.InterfaceTheme .title span {
  color: gray;
}
.InterfaceTheme .modes {
  display: flex;
  -moz-column-gap: 40px;
       column-gap: 40px;
  padding: 20px 0px 0px 0px;
}
.InterfaceTheme .modes .darkMode, .InterfaceTheme .modes .ligthMode {
  width: 100px;
  height: 160px;
  border: 4px solid #a8a8a8;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: relative;
}
.InterfaceTheme .modes .darkMode .tick, .InterfaceTheme .modes .ligthMode .tick {
  position: absolute;
  width: 25px;
  top: 8px;
  right: 35%;
}/*# sourceMappingURL=InterfaceTheme.css.map */