$blueColorForColumn: #0478d4;
$happeningEvents: #d5eaf7;
$currentDate: #0078d5;
$columnColor: #666666;
$calendarBackgroundColor: #f8f9f9;
$borderColor: #e2e2e2;
$dayBlock: #feffff;

.CalendarComp {
    display: flex;
    flex-direction: column;
    .Calendar {
        border-radius: 10px;
        box-shadow: 0px 0px 6px $columnColor;
        display: flex;
        background-color: $calendarBackgroundColor;
        width: fit-content;

        .DayColumn {

            h3 {
                text-align: center;
                font-size: inherit;
                font-weight: bold;
                color: $columnColor;
                margin: 0;
                padding: 8px 0px 8px 0px;
            }

            .listOfDays {
                display: flex;
                flex-direction: column;
                align-items: center;

                .DayBlock {
                    position: relative;
                    $blockDimension: 90px;
                    width: $blockDimension+20;
                    height: $blockDimension;
                    border-left: 1px solid $borderColor;
                    border-top: 1px solid $borderColor;
                    background-color: $dayBlock;

                    .EventCard {
                        display: flex;
                        column-gap: 6px;
                        cursor: pointer;
                        align-items: center;
                        width: 90%;
                        height: 24px;
                        color: white;
                        text-align: center;
                        position: relative;
                        border-radius: 6px;
                        margin: 36px 0px 0px 0px;
                        padding: 0px 6px 0px 2px;
                        background-color: #0078d5;

                        .title {
                            width: 70px;
                            overflow-x: hidden;
                            text-overflow: ellipsis;
                            height: inherit;
                            white-space: nowrap;
                        }

                        .whiteRect {
                            width: 8px;
                            height: 80%;
                            background-color: white;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                        }

                        img {
                            width: 15px
                        }
                    }

                    .day {
                        position: absolute;
                        left: 10px;
                        top: 8px;
                        padding: 1px 0px 2px 0px;
                        width: 24px;
                        text-align: center;
                        color: $columnColor;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}