.BillingHistory {
  width: -moz-fit-content;
  width: fit-content;
}
.BillingHistory .HeaderElm {
  display: flex;
  flex-direction: row;
  padding: 0px 0px 10px 0px;
}
.BillingHistory .HeaderElm .elm {
  color: #868686;
  padding: 10px 0px 0px 20px;
  width: 200px;
}
.BillingHistory .HeaderElm .Receipt {
  color: #868686;
  padding: 10px 0px 0px 20px;
  width: -moz-fit-content;
  width: fit-content;
}
.BillingHistory .historyList {
  height: 250px;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  padding: 10px 0px 0px 10px;
}
.BillingHistory .historyList::-webkit-scrollbar {
  width: 8px;
}
.BillingHistory .historyList::-webkit-scrollbar-track {
  background-color: transparent;
}
.BillingHistory .historyList::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}
.BillingHistory .historyList::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}
.BillingHistory .historyList .receipt {
  display: flex;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.4s;
}
.BillingHistory .historyList .receipt .elm {
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  width: 200px;
}
.BillingHistory .historyList .receipt .downloadElm {
  border-radius: 10px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  padding: 8px 16px 8px 16px;
  outline: none;
  border: 1px solid #b8b8b8;
  cursor: pointer;
}
.BillingHistory .historyList .receipt:hover {
  box-shadow: 0px 0px 10px rgba(134, 134, 134, 0.5);
}/*# sourceMappingURL=BillingHistory.css.map */