@import url("https://fonts.googleapis.com/css?family=Inter");
.AdminAcademicList {
  font-family: "Inter";
  flex-grow: 1;
  box-shadow: 1px 1px 10px rgb(67, 67, 67);
  border-radius: 10px;
  overflow-y: auto;
  height: 47vh;
  /* Custom scrollbar styles */
}
.AdminAcademicList .title {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 20px;
  display: block;
  color: #762984;
  font-weight: bold;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.AdminAcademicList table {
  padding: 0px 0px 20px 0px;
  width: 100%;
}
.AdminAcademicList table thead tr {
  background-color: #f2f2f2;
}
.AdminAcademicList table thead tr th {
  color: #232323;
  width: 120px;
  text-align: left;
  padding: 10px;
  border: 2px solid #f2f2f2;
  font-weight: 600;
}
.AdminAcademicList table thead tr .statsColumn {
  text-align: center;
}
.AdminAcademicList table thead tr .nameColumn {
  width: 200px;
  padding: 0px 0px 0px 20px;
}
.AdminAcademicList table tbody tr td {
  padding: 10px;
}
.AdminAcademicList table tbody tr .stats {
  text-align: center;
}
.AdminAcademicList table tbody tr .names {
  padding: 0px 0px 0px 20px;
}
.AdminAcademicList::-webkit-scrollbar {
  width: 8px;
}
.AdminAcademicList::-webkit-scrollbar-track {
  background-color: transparent;
  margin-top: 60px;
}
.AdminAcademicList::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}
.AdminAcademicList::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}/*# sourceMappingURL=AdminAcademicList.css.map */