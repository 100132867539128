.CalendarNavigation {
  width: 100%;
  display: flex;
  align-items: center;
  color: #4b4b4b;
  padding: 10px 0px 18px;
}
.CalendarNavigation .todayBtn {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  cursor: pointer;
}
.CalendarNavigation .todayBtn img {
  width: 20px;
}
.CalendarNavigation .monthNavigation {
  flex-grow: 2;
  cursor: pointer;
}
.CalendarNavigation .monthNavigation .innerDiv {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px auto 0px auto;
}
.CalendarNavigation .monthNavigation .innerDiv h1 {
  width: 200px;
  text-align: center;
  margin: 0px;
}
.CalendarNavigation .monthNavigation .innerDiv img {
  width: 16px;
  cursor: pointer;
}
.CalendarNavigation .yearMonthNav {
  cursor: pointer;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  position: relative;
}
.CalendarNavigation .yearMonthNav .monthList::-webkit-scrollbar {
  width: 8px;
}
.CalendarNavigation .yearMonthNav .monthList::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 10px 10px 10px 10px;
}
.CalendarNavigation .yearMonthNav .monthList::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 10px;
}
.CalendarNavigation .yearMonthNav .monthList::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}
.CalendarNavigation .yearMonthNav .yearSelection {
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  position: absolute;
  box-shadow: 0px 0px 10px gray;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
}
.CalendarNavigation .yearMonthNav .yearSelection .backFrontNav {
  padding: 10px 0px 10px 10px;
  -moz-column-gap: 10px;
       column-gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}
.CalendarNavigation .yearMonthNav .yearSelection .backFrontNav img {
  width: 16px;
}
.CalendarNavigation .yearMonthNav .yearSelection .option {
  border-radius: 10px;
  font-size: 12px;
  padding: 10px 10px 10px 10px;
}
.CalendarNavigation .yearMonthNav .yearSelection .option:hover {
  background-color: #888888;
  color: white;
}
.CalendarNavigation .yearMonthNav .navYearOrMonth {
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 10px gray;
  width: 60%;
  top: 0;
  left: 32px;
  border-radius: 10px;
}
.CalendarNavigation .yearMonthNav .navYearOrMonth .option {
  text-align: center;
  padding: 10px 0px 10px 0px;
  font-weight: bold;
  font-size: 12px;
}
.CalendarNavigation .yearMonthNav .navYearOrMonth .option:hover {
  background-color: #888888;
  color: white;
}
.CalendarNavigation .yearMonthNav img {
  width: 20px;
}
.CalendarNavigation .yearMonthNav .monthList {
  z-index: 1;
  position: absolute;
  left: -10px;
  display: flex;
  flex-wrap: wrap;
  width: 160px;
  height: 120px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px gray;
  background-color: white;
  overflow-y: auto;
}
.CalendarNavigation .yearMonthNav .monthList .month {
  padding: 10px;
  cursor: pointer;
}
.CalendarNavigation .yearMonthNav .monthList .month:hover {
  background-color: #d7d6d6;
}/*# sourceMappingURL=CalendarNavigation.css.map */