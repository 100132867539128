.AddTeacher {
    position: absolute;
    box-shadow: 0px 0px 10px gray;
    display: flex;
    flex-direction: column;
    // backdrop-filter: blur(10px);
    background-color: white;
    border-radius: 10px;
    row-gap: 20px;
    padding: 30px;
    left: 25%;
    max-width: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    animation: 600ms appear forwards ease-in;

    .profilePicture {
        display: flex;
        flex-direction: column;
        align-items: center;

        .circle {
            $radius: 100px;
            height: $radius;
            width: $radius;
            border: 5px solid white;
            box-shadow: 0px 0px 10px gray;
            border-radius: 50%;
            cursor: pointer;
            background-color: white;
            position: relative;

            .editAdd {
                position: absolute;
                box-shadow: 0px 0px 10px gray;
                border-radius: 50%;
                $editRadius: 30px;
                height: $editRadius;
                width: $editRadius;
                bottom: -10px;
                right: 0px;
                background-color: white;

                img {
                    width: $editRadius;
                }
            }

            img {
                width: $radius ;
                border-radius: 50%;
            }
        }

        .imageInputField {
            width: 0px;
            height: 0px;
        }
    }

    %commonInputStruct {
        padding: 10px 8px 10px 8px;
        outline: none;
        border: none;
        border-bottom: 1px solid gray;
        background-color: transparent;

        &::placeholder {
            color: black;
        }
    }

    .fullName {
        display: flex;
        column-gap: 38px;

        input {
            @extend %commonInputStruct;
            flex-grow: 1;
        }
    }

    .contacts {
        display: flex;
        column-gap: 38px;
        row-gap: 16px;
        flex-wrap: wrap;

        input {
            @extend %commonInputStruct;
            flex-grow: 1;
        }

        .address {
            flex-grow: 2;
        }
    }

    .genderDob {
        display: flex;
        justify-content: space-between;

        .gender {
            position: relative;
            cursor: pointer;

            .selectedGender {
                border: 1px solid rgb(41, 41, 41);
                width: 120px;
                padding: 6px 10px;
                border-radius: 10px;
            }

            .genderList {
                display: none;
                flex-direction: column;
                position: absolute;
                box-shadow: 0px 0px 10px gray;
                border-radius: 10px;

                .genderElm {
                    border-bottom: 1px solid gray;
                    width: 120px;
                    padding: 6px 10px;
                    background-color: white;

                    &:hover {
                        background-color: gray;
                        color: white;
                    }
                }
            }
        }

        .dob {
            display: flex;
            align-items: center;
            column-gap: 10px;
            input {
                height: 100%;
                border-radius: 10px;
                border: 1px solid gray;
                padding: 0px 8px 0px 8px;
                font-family: "Inter";
                outline: none;
            }
        }
    }

    .submission {
        display: flex;
        justify-content: space-between;

        %commonBtnStruc {
            padding: 10px 40px;
            color: white;
            cursor: pointer;
            font-size: medium;
        }

        .addTeacherBtn {
            @extend %commonBtnStruc;
            background-color: #0254ec;
            border: none;

        }

        .discTeacherBtn {
            @extend %commonBtnStruc;
            background-color: #cc2002;
            border: none;
        }
    }

}

@keyframes appear {
    0% {
        transform: translateY(-10px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}