// ---Palette---
$purple: #5375e2;
$brightPurple: #6054E3;
$blue: #54D0E3;
$lightPurple: #ebeffc;
$gray: #68686c;

.Home {
    position: relative;

    @mixin btn($color, $backgroundColor, $borderColor) {
        border-radius: 10px;
        border: 2px solid $borderColor;
        color: $color;
        padding: 6px 16px 10px 16px;
        background-color: $backgroundColor;
        transition: all 0.7s;
        text-decoration: none;
        width: fit-content;

        @if $backgroundColor ==transparent {
            &:hover {
                background-color: $borderColor;
                border-color: $borderColor;
                color: white;
                box-shadow: 1px 1px 10px $gray;
            }
        }

        @else {
            &:hover {
                background-color: darken($color: $backgroundColor, $amount: 10);
                border-color: darken($color: $borderColor, $amount: 10);
                color: $color;
                box-shadow: 1px 1px 10px $gray;
            }
        }

    }

    .navigation {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: white;

        .logo {
            display: flex;
            align-items: center;
            justify-self: center;
            column-gap: 5px;
        }

        .navLinks {
            display: flex;
            align-items: center;
            justify-self: center;
            column-gap: 30px;

            .link {
                text-decoration: none;
                color: $gray;
                transition: all 0.7s;

                &:hover {
                    color: $purple;
                }
            }

            .loginBtn {
                @include btn(black, transparent, $purple )
            }

            .registerBtn {
                @include btn(white, $purple, $purple )
            }
        }
    }

    .header {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: 0px auto 0px auto;
        padding: 50px 0px 50px 0px;
        column-gap: 250px;
        height: 50vh;

        .leftSec {
            width: 50%;
            height: fit-content;
            display: flex;
            flex-direction: column;

            .title-subtitle {
                width: 400px;

                .focusedWord {
                    color: $purple;
                }

                p {
                    color: $gray;
                }

            }

            .getStartedBtn {
                @include btn(white, $purple, $purple);
                margin-top: 16px;
            }
        }

        .rightSec {
            position: relative;

            .floatingCard {
                display: flex;
                column-gap: 6px;
                position: absolute;
                align-items: center;
                width: fit-content;
                z-index: 1;

                border: 2px solid $purple;
                border-radius: 10px;
                box-shadow: 1px 1px 10px $gray;
                padding: 2px 8px 2px 4px;
                background-color: white;

                img {
                    width: 30px;
                }

                .card-info {
                    display: flex;
                    flex-direction: column;

                    .card-title {
                        font-weight: bold;
                    }

                    .sub-Text {
                        font-size: 12px;
                        color: $gray;
                    }
                }
            }

            .card1 {
                top: 40px;
                left: -50px;
            }

            .card2 {
                top: 0px;
                right: -50px;
                flex-direction: column;
                align-items: center;

                img {
                    width: 40px;
                }

                .card-info {
                    align-items: center;
                }
            }

            .card3 {
                bottom: 0px;
                right: -20px;
            }

            .nonfilled-Circle {
                position: absolute;
                top: -10px;
                left: -10px;
                width: 250px;
                height: 250px;
                border-radius: 50%;
                border: 2px solid $brightPurple;
            }

            .filled-circle {
                width: 250px;
                height: 250px;
                border-radius: 50%;
                background-color: $brightPurple;
            }

        }
    }

    .HowItWorks {
        width: 100%;
        height: fit-content;
        margin-top: 50px;
        background-color: darken($color: $purple, $amount: 40);
        text-align: center;
        padding: 20px 0px 40px 0px;

        h2 {
            color: white;
        }

        .steps {
            display: flex;
            width: fit-content;
            margin: 0px auto 0px auto;
            align-items: center;

            .dashes {
                width: 200px;
                height: 0px;
                border: 0.5px dashed white;
            }

            .step {
                color: white;
                width: 250px;

                img {
                    width: 100px;
                }
            }
        }
    }
}